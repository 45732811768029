import React, { useState, useRef } from 'react'
import PropTypes from "prop-types"
import classNames from "classnames"

const SiteNavContext = React.createContext({})

/*
 * Bronson SiteNav component.
 *
 * Generated React component. Do not modify.
 */
export function SiteNav({
  testId,
  children,
  closeMenuText,
  className,
  ...otherProps /* in <div> tag */
}) {

  const [menuOpen, setMenuOpen] = useState(false)

  const [open, setOpen] = useState(null)

  /*
  React.useEffect(() => {
    console.log("set open to %o", open);
  }, [open]);
  */

  const contextValue = {
    // the value of "open" state
    "open": open,
    // callback function to update "open" state to a new ref object
    'setOpen': (ref) => (ref === open) ? setOpen(null) : setOpen(ref),
    // callback function to close the menu
    'closeMenu': () => setMenuOpen(false)
  }

  const classNameList = classNames(
    {
      "c-site-nav": true,
      "js-site-nav": true,
      //"js-is-ready": true,
      "is-active": menuOpen
    },
    className
  ).trim()

  // generated main result
  return (
    <div
      {...otherProps}
      data-testid={testId}
      className={classNameList}
    >
      <button
        className="c-site-nav__toggle js-site-nav__toggle"
        type="button"
        title="Show navigation"
        onClick={() => setMenuOpen(!menuOpen)}
        aria-expanded={menuOpen}
      >
        <i className="c-site-nav__toggle-icon" aria-hidden="true"></i>
        <span className="c-site-nav__toggle-close-label">{closeMenuText || "Close Menu"}</span>
      </button>
      <div className="c-site-nav__panel js-site-nav__navs" aria-hidden={!menuOpen}>
        <nav className="c-site-nav__navs" aria-label="primary">
          <SiteNavContext.Provider value={contextValue}>
            {children /* Use 'SiteNav.Area' component. */}
          </SiteNavContext.Provider>
        </nav>
      </div>
    </div>
  )
}

SiteNav.propTypes = {
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node // Bronson template: 'site-nav'. Use 'SiteNav.Area' component.
}

/*
 * Bronson SiteNavArea component (nested).
 *
 * Generated React component. Do not modify.
 */
function SiteNavArea({ children, ...otherProps /* in <ul> tag */ }) {
  // generated main result
  return (
    <ul {...otherProps} className="c-site-nav__nav">
      {children /* Use 'SiteNav.Item' component. */}
    </ul>
  )
}

SiteNavArea.propTypes = {
  children: PropTypes.node // Bronson template: 'site-nav-item'. Use 'SiteNav.Item' component.
}

SiteNavArea.displayName = "SiteNav.Area"
SiteNav.Area = SiteNavArea

/*
 * Bronson SiteNavItem component (nested).
 *
 * Generated React component. Do not modify.
 */
function SiteNavItem({
  navClassName,
  topAtBreakpoint,
  className,
  language,
  reversed,
  label,
  icon,
  dropdownTitle,
  active,
  onClick,
  children,
  ...otherProps /* in <li> tag */
}) {

  const areaRef = useRef(null);

  // generated
  const liClassNameList = classNames(
    {
      "c-site-nav__item ": true,
      "js-dropdown ": children,
      "c-site-nav__item--top-at-breakpoint ": topAtBreakpoint,
      "c-site-nav__item--language": language
    },
    navClassName
  ).trim()

  // generated
  const aClassNameList = classNames(
    {
      "c-site-nav__link ": true,
      "js-dropdown__button ": children,
      "is-active": active,
      "is-reversed": reversed
    },
    className
  ).trim()

  // generated
  function renderIfIcon() {
    if (icon) {
      return <i className="c-site-nav__link-icon" aria-hidden="true"></i>
    }
    return null
  }

  // generated
  function renderIfDropdownTitle() {
    if (dropdownTitle) {
      return <span className="c-site-nav-dropdown__title">{dropdownTitle}</span>
    }
    return null
  }

  // generated
  function renderIfDropdownItems(open) {
    if (children) {
      return (
        <nav
          className="c-site-nav-dropdown js-dropdown__panel"
          aria-hidden={open !== areaRef}
        >
          <ul className="c-site-nav-dropdown__list">
            {renderIfDropdownTitle()}
            {children /* Use 'SiteNav.Item.DropdownItem' component. */}
          </ul>
        </nav>
      )
    }
    return null
  }

  // generated main result
  return (
    <SiteNavContext.Consumer>
      {value =>
        <li ref={areaRef} {...otherProps} className={liClassNameList}
          tabIndex="0"
          onBlur={() => [
            // only close all items if the open item is this one, as this could have changed.
            (value.open === areaRef) && value.setOpen(null),
          ]}
          style={{ "outline": "none" }}
        >
          <a
            className={aClassNameList + (value.open === areaRef ? " is-active" : "")}
            /**
             * Note: OnMouseDown is applied before onBlur, other than onClick.
             *       If the action is performed in onClick, onBlur could already be performed, closing another menu.
             *       In case of e.g. VW6, closing another menu could move content, so the actual click doesn't happen anymore, which is an undesired behavior.
             *       Test this with VW6 menu and 2 items with subitems, open item 1 and click on item 2 (which will move as item 1 submenu disappears just before)
             */ 
            onMouseDown={(event) => [ // when clicked...
              onClick && [ // if click action available...
                !children && [ // if no subitems (this is a direct action)...
                  value.setOpen(null),
                  value.closeMenu(),
                ],
                onClick(event), // perform the click action
              ],
              children && [ // if subitems...
                value.setOpen(areaRef) // open this item (after onClick)
              ]
            ]}
            // no onClick action, see onMouseDown and comment above.
            aria-expanded={children && value.open === areaRef}
          >
            <span className="c-site-nav__link-label">{label}</span>
            {renderIfIcon()}
          </a>
          {renderIfDropdownItems(value.open)}
        </li>
      }
    </SiteNavContext.Consumer>
  )
}

SiteNavItem.propTypes = {
  navClassName: PropTypes.string, // Bronson template: 'nav-modifier'.
  topAtBreakpoint: PropTypes.bool, // Bronson template: 'top-at-breakpoint'.
  className: PropTypes.string, // Bronson template: 'modifier'.
  label: PropTypes.string, // Bronson template: 'label'.
  icon: PropTypes.bool, // Bronson template: 'has-icon'.
  dropdownTitle: PropTypes.string, // Bronson template: 'dropdown-title'.
  onClick: PropTypes.func, // Added for onClick attribute.
  children: PropTypes.node, // Bronson template: 'dropdown-items'. Use 'SiteNav.Item.DropdownItem' component.
  active: PropTypes.bool,
  language: PropTypes.bool,
  reversed: PropTypes.bool
}

/*
 * Bronson DropdownItem component (nested).
 *
 * Generated React component. Do not modify.
 */
function DropdownItem({
  children,
  active,
  onClick,
  ...otherProps /* in <li> tag */
}) {
  // generated
  const aClassNameList = classNames({
    "c-site-nav-dropdown__link ": true,
    "is-active ": active
  }).trim()

  // generated main result
  return (
    <li {...otherProps} className="c-site-nav-dropdown__item">
      <SiteNavContext.Consumer>
        {value =>
          <a
            className={aClassNameList}
            onClick={(clickParam) => onClick && [ // when a click action is available...
              value.setOpen(null), // close the open item that contains this dropdown item
              value.closeMenu(), // close whole menu/SiteNav (if openable menu, e.g. VW6, mobile)
              onClick(clickParam) // perform the click action
            ]
            }>
            { /* renderIfActive() */}
            {children}
          </a>
        }
      </SiteNavContext.Consumer>
    </li>
  )
}

DropdownItem.propTypes = {
  children: PropTypes.node, // Bronson template: 'text'.
  active: PropTypes.bool, // Bronson template: 'is-active'.
  onClick: PropTypes.func // Added for onClick attribute.
}

DropdownItem.displayName = "SiteNavItem.DropdownItem"
SiteNavItem.DropdownItem = DropdownItem

SiteNavItem.displayName = "SiteNav.Item"
SiteNav.Item = SiteNavItem


