import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

/*
 * Bronson SectionHeading component.
 *
 * Generated React component. Has modifications.
 */
export function SectionHeading({
  superscript,
  center,
  level,
  icon,
  className,
  children,
  testId,
  primary,
  subtitle,
  smallSpacing,
  alternative,
  ...otherProps /* in <header> tag */
}) {
  // generated
  const headerClassNameList = classNames(
    {
      "c-section-heading ": true,
      "c-section-heading--center ": center,
      // main class modifier convenience prop extension
      "c-section-heading--primary ": primary, // Convenience prop from Bronson variants.
      "c-section-heading--small-spacing ": smallSpacing, // Convenience prop from Bronson variants.
      "c-section-heading--subtitle ": subtitle && !center && !smallSpacing, // Convenience prop from Bronson variants.
      "c-section-heading--alternative ": alternative, // Convenience prop from Bronson variants.
    },
    className
  ).trim()

  // generated
  // tag containing a variable or condition
  const CustomTagSectionHeadingsLevel = `h${level}`

  // generated
  const iClassNameList = classNames({
    "c-icon ": true,
    [`c-icon--[${icon}] `]: true,
    "c-section-heading__icon ": true
  }).trim()

  // generated
  function renderIfSectionHeadingsIcon() {
    if (icon) {
      return <i className={iClassNameList}></i>
    }
    return null
  }

  // generated
  function renderIfSectionHeadingsSuperscript() {
    if (superscript) {
      return <sup>{superscript}</sup>
    }
    return null
  }

  // generated
  function renderIfSectionHeadingsTitle() {
    if (children) {
      return (
        <CustomTagSectionHeadingsLevel className="c-section-heading__title">
          {renderIfSectionHeadingsIcon()}
          <span className="c-section-heading__title-text">
            {renderIfSectionHeadingsSuperscript()}
            {children}
          </span>
        </CustomTagSectionHeadingsLevel>
      )
    }
    return null
  }

  // generated
  function renderIfSectionHeadingsText() {
    if (subtitle) {
      return <p className="c-section-heading__subtitle">{subtitle}</p>
    }
    return null
  }

  // generated main result
  return (
    <header
      {...otherProps}
      data-testid={testId}
      className={headerClassNameList}
    >
      {renderIfSectionHeadingsTitle()}
      {renderIfSectionHeadingsText()}
    </header>
  )
}

SectionHeading.propTypes = {
  superscript: PropTypes.string, // Bronson template: 'section-headings-superscript'.
  center: PropTypes.bool, // Bronson template: 'section-headings-center'.
  level: PropTypes.string.isRequired, // Bronson template: 'section-headings-headline'.
  icon: PropTypes.string, // Bronson template: 'section-headings-icon'.
  children: PropTypes.string, // Bronson template: 'section-headings-title'.
  className: PropTypes.string, // Bronson template: 'section-headings-modifier'.
  subtitle: PropTypes.node, // Bronson template: 'section-headings-text'.
  testId: PropTypes.string, // Added for data-testid attribute.
  /* Convenience props */
  primary: PropTypes.bool, // Convenience prop for c-section-heading--primary (Bronson template: 'section-headings-modifier').
  smallSpacing: PropTypes.bool, // Convenience prop for c-section-heading--small-spacing (Bronson template: 'section-headings-modifier').
  alternative: PropTypes.bool // Convenience prop for c-section-heading--alternative (Bronson template: 'section-headings-modifier').
}
