import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { FormContext } from "../Form/FormContext"
import { FormFieldContext } from "./FormFieldContext"

/*
 * Values for 'type' prop. 
 */
const typeValues = ['input', 'textarea', 'select', 'checkbox', 'other']

/*
 * Bronson FormField component.
 *
 * Generated React component. Do not modify.
 */
export function FormField({
  className,
  sideLink,
  labelElement = "label",
  labelText,
  children,
  notion,
  mark,
  infoIcon,
  id,
  errorMessage,
  testId,
  onClick,

  type,
  noFloatingLabel,

  ...otherProps /* in <div> tag */
}) {

  const context = React.useContext(FormContext)

  const [elementActive, setElementActive] = useState(false)

  const contextValue = {
    'setElementActive': (active) => setElementActive(active)
  }

  const formFloatingLabel = (context && context.floatingLabel)

  useEffect(() => {
    if (!type) {
      const valueHint = typeValues.join(', ')
      if (formFloatingLabel) {
        console.error("Warning: The prop `type` is required in <FormField> (possible values: "+valueHint+") because floating labels are enabled in <Form> element.")
      } else {
        console.warn("The prop `type` should be added to <FormField> (possible values: "+valueHint+"). This warning has no negative effect because floating labels are not enabled in the <Form> element.")
      }
    }
  })

  const standardLabel = (
    // <Form floatingLabel> prop not set 
    !formFloatingLabel ||
    // no floating when explicitly set
    noFloatingLabel || 
    // downstream element is active (e.g. contains text, triggered via onChange in element on callback)
    elementActive ||
    // no floating if not in [input, textarea]
    (type !== "input" && type !== "textarea") ||
    // no floating with InfoIcon
    infoIcon
  )

  // generated
  const divClassNameList = classNames(
    {
      "c-form-field ": true,
      "c-form-field--no-floating ": noFloatingLabel,
      "c-form-field--floating-textarea ": formFloatingLabel && !noFloatingLabel && (type === "textarea"),
      "is-active ": formFloatingLabel && standardLabel,
    },
    className
  ).trim()

  // generated
  function renderIfSideLink() {
    if (sideLink) {
      return (
        <a
          id={`${id}-side-link`}
          data-testId={`${testId}-side-link`}
          onClick={onClick}
          className="c-form-field__side-link"
        >
          {sideLink}
        </a>
      )
    }
    return null
  }

  // generated
  // tag containing a variable or condition
  const CustomTagLabelElement = `${labelElement}`

  // generated
  function renderIfNotion() {
    if (notion) {
      return <span className="c-form-field__notion">{mark || "* "} </span>
    }
    return null
  }

  // generated
  function renderIfLabel() {
    if (labelText) {
      return (
        <CustomTagLabelElement
          htmlFor={(labelElement === "label" && id) ? id : undefined}
          className="c-form-field__label"
          aria-describedby={sideLink && `${id}-side-link`}
        >
          {labelText}
          {renderIfNotion()}
        </CustomTagLabelElement>
      )
    }
    return null
  }

  // generated
  function renderIfInfoIcon() {
    if (infoIcon) {
      return <React.Fragment>{infoIcon}</React.Fragment>
    }
    return null
  }

  // generated
  function renderIfErrorMessage() {
    if (errorMessage) {
      return <React.Fragment>{errorMessage}</React.Fragment>
    }
    return null
  }

  // generated main result
  return (
    <div {...otherProps} id={id} data-testid={testId} className={divClassNameList}>
      {renderIfSideLink()}
      {renderIfLabel()}
      {renderIfInfoIcon()}
      <div className="c-form-field__box">
        <FormFieldContext.Provider value={contextValue}>
          {children}
        </FormFieldContext.Provider>
        {renderIfErrorMessage()}
      </div>
    </div>
  )
}

FormField.propTypes = {
  className: PropTypes.string, // Bronson template: 'modifiers'.
  sideLink: PropTypes.string, // Bronson template: 'side-link'.
  labelElement: PropTypes.string, // Bronson template: 'label-element'.
  labelText: PropTypes.node, // Bronson template: 'label'.
  notion: PropTypes.bool, // Bronson template: 'notion'.
  mark: PropTypes.string, // Bronson template: 'mark'.
  infoIcon: PropTypes.node, // Bronson template: 'bronson-info-icon'.
  id: PropTypes.string, // Bronson template: 'id'.
  errorMessage: PropTypes.node, // Bronson template: 'bronson-error-message'.
  testId: PropTypes.string, // Added for data-testid attribute.
  onClick: PropTypes.func, // Added for onClick attribute.

  /*
   Floating label support. Note that according to Bronson doc, the *Form* itself has a <Form floatingLabel> 
   prop to enable floating labels.
   */
  type: PropTypes.oneOf(typeValues), // Hint on contained element in order to properly handle floating labels.
  noFloatingLabel: PropTypes.bool // Bronson approach to disable floating label for e.g. checkbox
}
