import React from 'react'
import PropTypes from 'prop-types'

export function Fieldset({ children, testId, ...otherProps }) {

  return <div className="o-fieldset" data-testid={testId} {...otherProps}>{children}</div>

}

Fieldset.propTypes = {
  children: PropTypes.node.isRequired,
  testId: PropTypes.string
}

export function FieldsetRow({ children, testId, ...otherProps }) {

  return <div className="o-fieldset__row" data-testid={testId} {...otherProps}>{children}</div>

}

FieldsetRow.propTypes = {
  children: PropTypes.node.isRequired,
  testId: PropTypes.string
}

FieldsetRow.displayName = 'Fieldset.Row'
Fieldset.Row = FieldsetRow