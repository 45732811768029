import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { ReactLink } from "../Link/Link" 
/*
 * Bronson Breadcrumb component.
 *
 * Generated React component. Do not modify.
 */
export function Breadcrumb({
  testId,
  children,
  ...otherProps /* in <nav> tag */
}) {
  // generated main result
  return (
    <nav {...otherProps} data-testid={testId}>
      <ul className="c-breadcrumb">
        {children /* Use 'Breadcrumb.Item' component. */}
      </ul>
    </nav>
  )
}

Breadcrumb.propTypes = {
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node // Bronson template: 'breadcrumb-item'. Use 'Breadcrumb.Item' component.
}

/*
 * Bronson BreadcrumbItem component (nested).
 *
 * Generated React component. Do not modify.
 */
function BreadcrumbItem({
  state,
  children,
  normalLink,
  onClick,
  href,
  current,
  disabled,
  testId,
  ...otherProps /* in <a> tag */
}) {
  // generated
  const aClassNameList = classNames({
    "c-breadcrumb__link ": true,
    "is-current": current,
    "is-disabled": disabled,
    [`${state} `]: state
  }).trim()

  // generated main result
  return (
    <li className="c-breadcrumb__item" data-testid={testId}>
      <ReactLink className={aClassNameList} normalLink={normalLink} onClick={onClick} href={href} {...otherProps}>
        <span className="c-breadcrumb__label">{children}</span>
      </ReactLink>
    </li>
  )
}

BreadcrumbItem.propTypes = {
  state: PropTypes.string, // Bronson template: 'state'.
  children: PropTypes.node, // Bronson template: 'title'.
  onClick: PropTypes.func, // Added for onClick attribute.
  href: PropTypes.string, // Added for href attribute.
  normalLink: PropTypes.bool,
  current: PropTypes.bool,
  disabled: PropTypes.bool,
  testId: PropTypes.string
}

BreadcrumbItem.displayName = "Breadcrumb.Item"
Breadcrumb.Item = BreadcrumbItem
