import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { ReactLink } from "../Link/Link"

/*
 * Bronson ContextBar component.
 *
 * Generated React component. Do not modify.
 */
export function ContextBar({
  testId,
  children,
  ...otherProps /* in <nav> tag */
}) {
  // generated main result
  return (
    <div className="c-context-bar">
      <div className="c-context-bar__panel" aria-hidden="true">
        <nav
          {...otherProps}
          data-testid={testId}
          className="c-context-bar__navs"
          aria-label="primary"
        >
          {children /* Use 'ContextBar.SiteNav' component. */}
        </nav>
      </div>
    </div>
  )
}

ContextBar.propTypes = {
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node // Bronson template: 'site-nav'. Use 'ContextBar.SiteNav' component.
}

/*
 * Bronson SiteNav component (nested).
 *
 * Generated React component. Do not modify.
 */
function SiteNav({ children, ...otherProps /* in <ul> tag */ }) {
  // generated main result
  return (
    <ul {...otherProps} className="c-context-bar__nav">
      {children /* Use 'ContextBar.SiteNav.Item' component. */}
    </ul>
  )
}

SiteNav.propTypes = {
  children: PropTypes.node // Bronson template: 'site-nav-item'. Use 'ContextBar.SiteNav.Item' component.
}

/*
 * Bronson SiteNavItem component (nested).
 *
 * Generated React component. Do not modify.
 */
function SiteNavItem({
  noLink,
  title,
  label,
  contextLabel,
  children,
  ...otherProps /* in no tag */
}) {
  // generated
  const spanClassNameList = classNames({
    "c-context-bar__title ": title,
    "c-context-bar__nav-label ": !title
  }).trim()

  // generated
  function renderIfNoLink() {
    if (noLink) {
      return (
        <li>
          <span className={spanClassNameList}>{label}</span>
        </li>
      )
    }
    return null
  }

  // generated
  function renderIfContextLabel() {
    if (contextLabel) {
      return (
        <li>
          <span className="c-context-bar__nav-label">{contextLabel}</span>
        </li>
      )
    }
    return null
  }

  // generated main result
  return (
    <React.Fragment>
      {renderIfNoLink()}
      {renderIfContextLabel()}
      {children /* Use 'ContextBar.SiteNav.Item.ContextItem' component. */}
    </React.Fragment>
  )
}

SiteNavItem.propTypes = {
  noLink: PropTypes.bool, // Bronson template: 'has-no-link'.
  title: PropTypes.bool, // Bronson template: 'title'.
  label: PropTypes.string, // Bronson template: 'label'.
  contextLabel: PropTypes.string, // Bronson template: 'context-label'.
  children: PropTypes.node // Bronson template: 'context-items'. Use 'ContextBar.SiteNav.Item.ContextItem' component.
}

/*
 * Bronson ContextItem component (nested).
 *
 * Generated React component. Do not modify.
 */
function ContextItem({
  contextBarIcon,
  iconLabel,
  onClick,
  href,
  normalLink,
  linkProps,
  ...otherProps /* in <li> tag */
}) {
  // generated
  const iClassNameList = classNames({
    "c-icon ": true,
    [`c-icon--[${contextBarIcon}] `]: true,
    "c-context-bar__icon ": true
  }).trim()

  // generated
  function renderIfIconLabel() {
    if (iconLabel) {
      return <span className="c-context-bar__icon-label">{iconLabel}</span>
    }
    return null
  }

  // generated main result
  return (
    <li {...otherProps}>
      <ReactLink className="c-context-bar__link" onClick={onClick} href={href} normalLink={normalLink} {...linkProps}>
        <i className={iClassNameList}></i>
        {renderIfIconLabel()}
      </ReactLink>
    </li>
  )
}

ContextItem.propTypes = {
  contextBarIcon: PropTypes.string, // Bronson template: 'context-bar-icon'.
  iconLabel: PropTypes.string, // Bronson template: 'icon-label'.
  onClick: PropTypes.func, // Added for onClick attribute.
  normalLink: PropTypes.bool,
  href: PropTypes.string,
  linkProps: PropTypes.object
}

ContextItem.displayName = "ContextBar.SiteNav.Item.ContextItem"
SiteNavItem.ContextItem = ContextItem

SiteNavItem.displayName = "ContextBar.SiteNav.Item"
SiteNav.Item = SiteNavItem

SiteNav.displayName = "ContextBar.SiteNav"
ContextBar.SiteNav = SiteNav
