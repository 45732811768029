import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

/*
 * Bronson ProductInfoSummary component.
 *
 * Generated React component. Do not modify.
 */
export function ProductInfoSummary({
  className,
  title,
  subtitle,
  infoLabel,
  infoValue,
  testId,
  compact,
  imgSrc,
  imgSrcSet,
  imgSizes,
  imgAlt,
  imgProps,
  ...otherProps /* in <div> tag */
}) {
  // generated
  const divClassNameList = classNames(
    {
      "c-product-info-summary ": true,
      // main class modifier convenience prop extension
      "c-product-info-summary--compact ": compact // Convenience prop from Bronson variants.
    },
    className
  ).trim()

  // generated
  function renderIfProductInfoSummaryImg() {
    if (imgSrc || imgSrcSet) {
      return (
        <span className="c-product-info-summary__img">
          <img src={imgSrc} srcSet={imgSrcSet} sizes={imgSizes} alt={imgAlt} {...imgProps}></img>
        </span>
      )
    }
    return null
  }

  // generated
  function renderIfProductInfoSummarySubtitle() {
    if (subtitle) {
      return (
        <span className="c-product-info-summary__subtitle">{subtitle}</span>
      )
    }
    return null
  }

  // generated main result
  return (
    <div {...otherProps} data-testid={testId} className={divClassNameList}>
      {renderIfProductInfoSummaryImg()}
      <span className="c-product-info-summary__heading">
        <span className="c-product-info-summary__title">{title}</span>
        {renderIfProductInfoSummarySubtitle()}
      </span>
      <span className="c-product-info-summary__info">
        <span className="c-product-info-summary__info-label">{infoLabel}</span>
        <span className="c-product-info-summary__info-value">{infoValue}</span>
      </span>
    </div>
  )
}

ProductInfoSummary.propTypes = {
  className: PropTypes.string, // Bronson template: 'product-info-summary-modifier'.
  title: PropTypes.node, // Bronson template: 'product-info-summary-title'.
  subtitle: PropTypes.node, // Bronson template: 'product-info-summary-subtitle'.
  infoLabel: PropTypes.node, // Bronson template: 'product-info-summary-info-label'.
  infoValue: PropTypes.node, // Bronson template: 'product-info-summary-info-value'.
  testId: PropTypes.string, // Added for data-testid attribute.
  imgSrc: PropTypes.string, // Bronson template: 'product-info-summary-img'.
  imgSrcSet: PropTypes.string, 
  imgSizes: PropTypes.string, 
  imgAlt: PropTypes.string, // Bronson template: 'product-info-summary-img-alt'.
  imgProps: PropTypes.object,
  /* Convenience props */
  compact: PropTypes.bool // Convenience prop for c-product-info-summary--compact (Bronson template: 'product-info-summary-modifier').
}
