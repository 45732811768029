import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

/*
 * Bronson RadioButton component.
 *
 * Generated React component. Do not modify.
 */
export function RadioButton({
  error,
  name,
  children,
  testId,
  ...otherProps /* in <input> tag */
}) {
  // generated
  const inputClassNameList = classNames({
    "c-radio__input ": true,
    "is-error ": error
  }).trim()

  // generated main result
  return (
    <label className="c-radio">
      <input
        {...otherProps}
        data-testid={testId}
        className={inputClassNameList}
        type="radio"
        name={`${name || "radio-button"}`}
      ></input>
      <span className="c-radio__label">{children}</span>
    </label>
  )
}

RadioButton.propTypes = {
  error: PropTypes.bool, // Bronson template: 'radio-button-is-error'.
  name: PropTypes.string, // Bronson template: 'radio-button-name'.
  // Bronson template: 'radio-button-attribute'. Replaced by {...otherProps}.
  children: PropTypes.node, // Bronson template: 'radio-button-label'.
  testId: PropTypes.string // Added for data-testid attribute.
}
