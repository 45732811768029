/* eslint-disable react/no-unused-prop-types */
import React, { useState } from 'react'
import Flatpickr from 'react-flatpickr'
import pl from 'flatpickr/dist/l10n/pl'
import it from 'flatpickr/dist/l10n/it'
import de from 'flatpickr/dist/l10n/de'
import cs from 'flatpickr/dist/l10n/cs'
import es from 'flatpickr/dist/l10n/es'
import fr from 'flatpickr/dist/l10n/fr'
import nl from 'flatpickr/dist/l10n/nl'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import TextMaskInput from 'react-text-mask'

const disabledButtonStyle = { pointerEvents: 'none' }

export function DatePicker({
  className = '',
  value: customValue,
  error = false,
  datePickerclassName = '',
  noBackground = true,
  dateFormat = 'Y-m-d',
  locale,
  maxDate,
  minDate,
  disabled = false,
  readOnly = false,
  onBlur,
  onChange: customOnChange,
  name,
  mode = 'single',
  showMask,
  guide,
  placeholder,
  flatpickrOptions,
  flatpickrProps,
  ...otherProps
}) {
  const [date, setDate] = useState(customValue || '')

  React.useEffect(() => {
    setDate(customValue);
  }, [customValue])

  const componentDatePickerWrapperClass = classNames(
    {
      'c-input  ': true,
      'c-input--date  ': true,
    },
    className
  ).trim()

  const componentDatePickerClass = classNames(
    {
      'c-input__input  ': true,
      'is-error  ': error,
    },
    datePickerclassName
  ).trim()

  const componentDatePickerButtonClass = classNames({
    'c-input__addon  ': true,
    'c-input__addon--no-background  ': noBackground,
  }).trim()

  const componentDatePickerButtonIconClass = classNames({
    'c-icon  ': true,
    'c-icon--[semantic-calendar]  ': true,
  }).trim()

  function prepareMask() {
    const separator = dateFormat.substring(1, 2)
    const tokens = dateFormat.split(separator).map(item => {
      return item === item.toUpperCase()
        ? [/\d/, /\d/, /\d/, /\d/]
        : [/\d/, /\d/]
    })

    return [].concat(tokens[0], separator, tokens[1], separator, tokens[2])
  }
  function preparePlaceholder() {
    const separator = dateFormat.substring(1, 2)
    const tokens = dateFormat.split(separator).map(item => {
      return item === item.toUpperCase()
        ? `${item.toLowerCase()}${item.toLowerCase()}${item.toLowerCase()}${item.toLowerCase()}`
        : `${item}${item}`
    })
    return tokens.join(separator)
  }

  function setLocale() {
    switch (locale) {
      case 'pl':
        return pl.pl
      case 'de':
        return de.de
      case 'it':
        return it.it
      case 'cs':
        return cs.cs
      case 'es':
        return es.es
      case 'fr':
        return fr.fr
      case 'nl':
        return nl.nl
      default:
        return null
    }
  }

  function onChange(e) {
    const value = e.target === undefined ? e : e.target.value
    if (
      (!guide && value.length === preparePlaceholder().length) ||
      (!!guide && value.indexOf('_') === -1)
    ) {
      setDate(value)
      customOnChange(value)
    }
  }

  function renderInput() {
    return (
      <TextMaskInput
        {...otherProps}
        className={componentDatePickerClass}
        type="text"
        value={date}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        readOnly={readOnly}
        data-input
        mask={prepareMask()}
        showMask={!!showMask}
        guide={!!guide}
        placeholder={placeholder || preparePlaceholder()}
      />
    )
  }

  function renderIcon() {
    return (
      <i className={componentDatePickerButtonIconClass} aria-hidden="true" />
    )
  }

  function renderButton() {
    return (
      <button
        className={componentDatePickerButtonClass}
        title="toggle"
        type="button"
        data-toggle
        style={readOnly || disabled ? disabledButtonStyle : undefined}
      >
        {renderIcon()}
      </button>
    )
  }

  return (
    <div className={componentDatePickerWrapperClass}>
      <Flatpickr
        {...flatpickrProps} 
        value={date}
        onChange={(_, value) => onChange(value)}
        options={{
          wrap: true,
          allowInput: !readOnly,
          mode,
          locale: setLocale(),
          dateFormat,
          disableMobile: true,
          maxDate,
          minDate,
          nextArrow: '<i class="c-icon c-icon--[semantic-forward] c-icon-–simple"></i>',
          prevArrow: '<i class="c-icon c-icon--[semantic-back] c-icon-–simple"></i>',
          monthSelectorType: 'static',
          ...flatpickrOptions
        }}
        style={{ width: '100%', display: 'flex' }}
      >
        {renderInput()}
        {renderButton()}
      </Flatpickr>
    </div>
  )
}

DatePicker.propTypes = {
  className: PropTypes.string,
  datePickerclassName: PropTypes.string,
  error: PropTypes.bool,
  noBackground: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  mode: PropTypes.string,
  dateFormat: PropTypes.string,
  showMask: PropTypes.bool,
  guide: PropTypes.bool,
  maxDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  minDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]),
  flatpickrOptions: PropTypes.object,
  flatpickrProps: PropTypes.object,
}