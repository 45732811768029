import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Button } from "../Button/Button"
import { ReactLink, linkToType } from "../Link/Link"

/**
 * Default localizations for selected locales, which can be passed as locale prop.
 * 
 * Note: A localization object can be passed instead with the localization prop.
 */
const localization_default = { 
  'de': {
    'month.01.name': "Januar",
    'month.01.name.short': "jan",
    'month.02.name': "Februar",
    'month.02.name.short': "feb",
    'month.03.name': "März",
    'month.03.name.short': "mär",
    'month.04.name': "April",
    'month.04.name.short': "apr",
    'month.05.name': "Mai",
    'month.05.name.short': "mai",
    'month.06.name': "Juni",
    'month.06.name.short': "jun",
    'month.07.name': "Juli",
    'month.07.name.short': "jul",
    'month.08.name': "August",
    'month.08.name.short': "aug",
    'month.09.name': "September",
    'month.09.name.short': "sep",
    'month.10.name': "Oktober",
    'month.10.name.short': "okt",
    'month.11.name': "November",
    'month.11.name.short': "nov",
    'month.12.name': "Dezember",
    'month.12.name.short': "dez",
  }
}

const InboxContext = React.createContext({})

/*
 * Bronson Inbox component.
 *
 * Generated React component.
 */
export function Inbox({
  filterTitle,
  filterElements,
  tableHeader,
  locale,
  localization,
  testId,
  children,
  ...otherProps /* in <div> tag */ }
) {

  const contextValue = {
    "localization": localization ? localization : localization_default[locale],
    "tableHeader": tableHeader,
  }

  // generated main result
  return (
    <React.Fragment>
      {filterTitle || filterElements?
        <div className="c-inbox-filter">
          <h3 className="c-inbox-filter__title">
            {filterTitle}
          </h3>
          <div className="c-inbox-filter__body">
            <div className="o-inline-group o-inline-group--small o-inline-group--stretch">
              {filterElements}
            </div>
          </div>
        </div>
        :
        <></>
      }
      <div className="c-inbox-table" data-testid={testId} {...otherProps}>
        <InboxContext.Provider value={contextValue}>
          {children /* Use 'Inbox.Section' component. */}
        </InboxContext.Provider>
      </div>
    </React.Fragment>
  )
}

Inbox.propTypes = {
  filterTitle: PropTypes.node,
  filterElements: PropTypes.array, // Bronson template: 'input'. Use 'Inbox.Input' component.
  tableHeader: PropTypes.array,
  locale: PropTypes.string,
  localization: PropTypes.object, // can be used to overwrite default localizations derived from locale.
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node // Bronson template: 'year'. Use 'Inbox.Section' component.
}

/*
 * Bronson FilterElement component (nested).
 *
 * Generated React component. Do not modify.
 */
function FilterElement({ children, testId, ...otherProps /* in <div> tag */ }) {
  // generated main result
  return (
    <div {...otherProps} data-testid={testId} className="o-inline-group__item">
      {children}
    </div>
  )
}

FilterElement.propTypes = {
  children: PropTypes.node, // Bronson template: 'include'.
  testId: PropTypes.string // Added for data-testid attribute.
}

FilterElement.displayName = "Inbox.FilterElement"
Inbox.FilterElement = FilterElement

/*
 * Bronson Section component (nested).
 *
 * Generated React component. 
 */
function Section({
  year,
  showYear,
  showTableHeader,
  testId,
  children,
  ...otherProps /* in no tag */
}) {

  const context = React.useContext(InboxContext)

  // generated
  function renderIfShowYear() {
    if (showYear) {
      return <h4 className="c-inbox-table__subheading">{year}</h4>
    }
    return null
  }

  // generated
  function renderIfShowTableHeader() {
    if (showTableHeader && context.tableHeader) {
      return (
        <thead>
          <tr>
            {context.tableHeader.map((item, index) => 
              <th scope="col" key={index} className="c-inbox-table__th">
                {item}
              </th>
            )}
          </tr>
        </thead>
      )
    }
    return null
  }

  // generated main result
  return (
    <React.Fragment>
      {renderIfShowYear()}
      <div className="c-table-wrapper" data-testid={testId} {...otherProps}>
        <table>
          {renderIfShowTableHeader()}
          <tbody className="c-inbox-table__body">
            {children /* Use 'Inbox.Message' component. */}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  )
}

Section.propTypes = {
  year: PropTypes.string, // Bronson template: 'year'.
  showYear: PropTypes.bool, // Bronson template: 'show-year'.
  showTableHeader: PropTypes.bool, // Bronson template: 'first'.
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node // Bronson template: 'message'. Use 'Inbox.Message' component.
}

Section.displayName = "Inbox.Section"
Inbox.Section = Section

/*
 * Bronson Message component (nested).
 *
 * Generated React component.
 */
function Message({
  year,
  month,
  day,
  extraInfo,
  read,
  download,
  to,
  onItemClick,
  onDownloadClick,
  onDeleteClick,
  testId,
  children,
  ...otherProps /* in <tr> tag */
}) {

  const context = React.useContext(InboxContext)

  // generated
  const trClassNameList = classNames({
    "c-inbox-table__tr ": true,
    "is-unread ": !read
  }).trim()

  // generated
  function renderIfDownload() {
    if (download) {
      return <Button element="a" icon="semantic-download" secondary className="c-table__btn" hiddenLabel onClick={onDownloadClick}>Download</Button>
    }
    return null
  }

  // generated
  function renderIfExtraInfo() {
    if (extraInfo) {
      return <p className="c-inbox-table__extra-info">{extraInfo}</p>
    }
    return null
  }

  // generated
  function renderUnlessDownload() {
    if (!download) {
      return <Button icon="semantic-delete" secondary className="c-table__btn" hiddenLabel onClick={onDeleteClick}>Delete</Button>
    }
    return null
  }

  const monthName = context.localization[`month.${month}.name`]
  const monthNameShort = context.localization[`month.${month}.name.short`]

  // generated main result
  return (
    <tr {...otherProps} data-testid={testId} className={trClassNameList}>
      <td className="c-inbox-table__td c-inbox-table__td--date">
        <time
          className="c-inbox-table__date"
          data-inbox-day={day}
          data-inbox-month={monthNameShort}
          dateTime={`${year}-${month}-${day}`}
          title={`${day}. ${monthName} ${year}`}
        >
          {`${day}. ${monthName}`}
        </time>
      </td>
      <td className="c-inbox-table__td c-inbox-table__td--action">
        {renderIfDownload()}
      </td>
      <td className="c-inbox-table__td c-inbox-table__td--subject">
        {renderIfExtraInfo()}
        <ReactLink onClick={onItemClick} to={to ? to : "#"} className="c-inbox-table__link">
          {children}
        </ReactLink>
      </td>
      <td className="c-inbox-table__td c-inbox-table__td--action">
        {renderUnlessDownload()}
      </td>
    </tr>
  )
}

Message.propTypes = {
  year: PropTypes.string, // Bronson template: 'year'.
  month: PropTypes.string, // Bronson template: 'month'.
  day: PropTypes.string, // Bronson template: 'day'.
  read: PropTypes.bool, // Bronson template: 'read'.
  download: PropTypes.bool, // Bronson template: 'download'.
  extraInfo: PropTypes.node, // Bronson template: 'extra-info'.
  to: linkToType,
  onItemClick: PropTypes.func,
  onDownloadClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node, // Bronson template: 'title'.
}

Message.displayName = "Inbox.Message"
Inbox.Message = Message

