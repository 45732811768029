import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

/*
 * Bronson Toggle component.
 *
 * Generated React component. Do not modify.
 */
export function Toggle({
  className,
  id,
  onOff,
  textOther,
  inputType,
  children,
  testId,
  ...otherProps /* in <input> tag */
}) {
  // generated
  const divClassNameList = classNames(
    {
      "c-toggle ": true,
      "c-toggle--on-off ": onOff
    },
    className
  ).trim()

  // generated
  function renderIfToggleOnOff() {
    if (onOff) {
      return <span className="c-toggle__text">{textOther}</span>
    }
    return null
  }

  // generated main result
  return (
    <div className={divClassNameList}>
      <label htmlFor={id}>
        {renderIfToggleOnOff()}
        <input
          {...otherProps}
          data-testid={testId}
          className="c-toggle__input"
          type={inputType}
          name="toggle"
          id={id}
        ></input>
        <span className="c-toggle__text">{children}</span>
      </label>
    </div>
  )
}

Toggle.propTypes = {
  className: PropTypes.string, // Bronson template: 'toggle-modifier'.
  id: PropTypes.string, // Bronson template: 'id'.
  onOff: PropTypes.bool, // Bronson template: 'toggle-on-off'.
  textOther: PropTypes.node, // Bronson template: 'toggle-text-other'.
  inputType: PropTypes.string, // Bronson template: 'toggle-input-type'.
  // Bronson template: 'toggle-attributes'. Replaced by {...otherProps}.
  children: PropTypes.node, // Bronson template: 'toggle-text'.
  testId: PropTypes.string // Added for data-testid attribute.
}
