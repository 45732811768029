import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

/*
 * Bronson InstructionStepper component.
 *
 * Generated React component. Do not modify.
 */
export function InstructionStepper({
  className,
  testId,
  children,
  numbered,
  vertical,
  ...otherProps /* in <ol> tag */
}) {
  // generated
  const olClassNameList = classNames(
    {
      "c-instruction-stepper ": true,
      // main class modifier convenience prop extension
      "c-instruction-stepper--numbered ": numbered, // Convenience prop from Bronson variants.
      "c-instruction-stepper--vertical ": vertical // Convenience prop from Bronson variants.
    },
    className
  ).trim()

  // generated main result
  return (
    <ol {...otherProps} data-testid={testId} className={olClassNameList}>
      {children /* Use 'InstructionStepper.Step' component. */}
    </ol>
  )
}

InstructionStepper.propTypes = {
  className: PropTypes.string, // Bronson template: 'instruction-stepper-modifier'.
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node, // Bronson template: 'instruction-stepper-step'. Use 'InstructionStepper.Step' component.
  /* Convenience props */
  numbered: PropTypes.bool, // Convenience prop for c-instruction-stepper--numbered (Bronson template: 'instruction-stepper-modifier').
  vertical: PropTypes.bool // Convenience prop for c-instruction-stepper--vertical (Bronson template: 'instruction-stepper-modifier').
}

/*
 * Bronson InstructionStepperStep component (nested).
 *
 * Generated React component. Do not modify.
 */
function InstructionStepperStep({
  state,
  displayIcons,
  icon,
  title,
  children,
  ...otherProps /* in <li> tag */
}) {
  // generated
  const liClassNameList = classNames({
    "c-instruction-stepper__step ": true,
    [`${state} `]: state
  }).trim()

  // generated
  const iClassNameList = classNames({
    "c-icon ": true,
    [`c-icon--[${icon}] `]: true
  }).trim()

  // generated
  function renderIfDisplayIcons() {
    if (displayIcons) {
      return <i className={iClassNameList}></i>
    }
    return null
  }

  // generated
  function renderIfDescription() {
    if (children) {
      return (
        <span className="c-instruction-stepper__description">{children}</span>
      )
    }
    return null
  }

  // generated main result
  return (
    <li {...otherProps} className={liClassNameList}>
      <figure className="c-instruction-stepper__item">
        {renderIfDisplayIcons()}
      </figure>
      <div className="c-instruction-stepper__detail">
        <span className="c-instruction-stepper__title">{title}</span>
        {renderIfDescription()}
      </div>
    </li>
  )
}

InstructionStepperStep.propTypes = {
  state: PropTypes.string, // Bronson template: 'state'.
  displayIcons: PropTypes.bool, // Bronson template: 'instruction-stepper-display-icons'.
  icon: PropTypes.string, // Bronson template: 'icon'.
  title: PropTypes.string, // Bronson template: 'title'.
  children: PropTypes.node // Bronson template: 'description'.
}

InstructionStepperStep.displayName = "InstructionStepper.Step"
InstructionStepper.Step = InstructionStepperStep
