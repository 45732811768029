import React, { useState, useEffect } from "react"
import ReactDOM from "react-dom"
import PropTypes from "prop-types"
import classNames from "classnames"
import Flickity from 'react-flickity-component'

/*
 * Bronson CompareAndSelect component.
 *
 * Generated React component with manual logic and adaption of Bronson workarounds to Flickity.
 */
export function CompareAndSelect({
  testId,
  inputGroup,
  buttonVariant = false,
  radioButtonSection,
  defaultSelected,
  children,
  onChange,
  ...otherProps /* in <div> tag */
}) {

  const [flickityContainerRef, setFlickityContainerRef] = useState(null);
  const [flickityReactRef, setFlickityReactRef] = useState(null);
  const [flickityRef, setFlickityRef] = useState(null);

  const [selected, setSelected] = useState(defaultSelected)

  const [windowSize, setWindowSize] = useState(null)
  const [sliderControlsNeeded, setSliderControlsNeeded] = useState(null)

  useEffect(() => {
    setSelected(defaultSelected)
    if (flickityRef) {
      flickityRef.select(defaultSelected)
    }
  }, [defaultSelected])

  function updateSelected(newSelected) {
    if (newSelected == selected) return

    setSelected(newSelected)
    if (flickityRef
      && !sliderControlsNeeded) // do not change flickity selected when slideable, because there are cases where flickity moves the selected to a bad position due to the current grouping.
    {
      flickityRef.select(newSelected)
    }

    if (onChange) {
      onChange(newSelected)
    }
  }

  /*
   Note: Bronson has a hacky workaround in order to show sliding controls only when 
         there's an actual sliding due to the screen size. 
         (See the description in Bronson JS code)
         
         The controls need to be decorated with a changing is-slideable class name,
         whether the slider is actually sliding or not, depending on its size and content 
         size.
         Therefore we track the window resize and calculate the needed conten, which is not
         really efficient.
  */

  // Resize
  const isClient = typeof window === 'object';

  function handleResize() {
    /* Alternative approach to the tag using a size object
    const size = {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    }
    */
    const sizeTag = isClient ? (window.innerWidth * window.innerHeight + (window.innerWidth - window.innerHeight)) : 0
    setWindowSize(sizeTag)
  }

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    window.addEventListener('resize', handleResize);

    // handle size once initially
    handleResize()

    return () => window.removeEventListener('resize', handleResize);

  }, []); // Empty array ensures that effect is only run on mount and unmount

  useEffect(() => {
    if (flickityRef == null) return

    flickityRef.on('ready', () => {
      onWindowResize()
      flickityRef.select(defaultSelected)
    })

  }, [flickityRef])

  // on size update
  useEffect(() => {
    onWindowResize()
  }, [windowSize])

  function onWindowResize() {
    if (!flickityContainerRef) return
    if (!flickityRef) return
    if (!flickityReactRef) return

    // Note: See Bronson code
    const flickityDomNode = ReactDOM.findDOMNode(flickityReactRef)
    flickityDomNode.classList.remove('flickity-resize');

    flickityRef.resize()

    // Note: See Bronson code
    const size = flickityContainerRef.getBoundingClientRect().width
    const slideableWidth = flickityRef.getCellElements().reduce((accumulator, child) => accumulator + child.clientWidth, 0)
    const slideable = (slideableWidth > size)

    const changed = (slideable != sliderControlsNeeded)
    setSliderControlsNeeded(slideable)

    if (changed) {
      updateSliderControls(slideable)
    }
    flickityDomNode.classList.add('flickity-resize');
  }

  function updateSliderControls(sliderControlsNeeded) {

    if (sliderControlsNeeded) {
      // flickityRef.select(selected)
    }

    if (!flickityContainerRef) return

    const containerDomNode = ReactDOM.findDOMNode(flickityContainerRef)
    const pageDotsHTMLCollection = containerDomNode.getElementsByClassName('flickity-page-dots')
    const pageDots = pageDotsHTMLCollection.item(0)

    flickityRef.isDraggable = sliderControlsNeeded

    if (pageDots) {
      if (sliderControlsNeeded) {
        pageDots.classList.add('is-slideable')
      } else {
        pageDots.classList.remove('is-slideable')
      }
    }

    const flickityDomNode = ReactDOM.findDOMNode(flickityReactRef)
    if (sliderControlsNeeded) {
      flickityDomNode.classList.add('is-draggable')
    } else {
      flickityDomNode.classList.remove('is-draggable')
    }

  }

  /*
  useEffect(() => {
    console.log("useEffect sliderControlsNeeded: "+sliderControlsNeeded)
    if (sliderControlsNeeded == null) return
    updateSliderControls(sliderControlsNeeded)
  }, [sliderControlsNeeded])
  */

  // End of controls handling.


  const flickityOptions = {
    cellSelector: '.js-compare-and-select__item',
    cellAlign: 'center',
    groupCells: '100%',
    initialIndex: defaultSelected,
    resize: false,
  }

  const flickityClassNameList = classNames({
    "c-compare-and-select-container__inner ": true,
    "js-compare-and-select ": true,
    //"flickity-resize": true, // Note: There's a Bronson CSS for height:100% depending on this class. 
    "is-initialized": true, // Bronson 
  }).trim()

  // generated main result
  return (
    <div
      {...otherProps}
      data-testid={testId}
      className="c-compare-and-select-container"
      ref={c => setFlickityContainerRef(c)}
    >
      <Flickity
        elementType="div"
        className={flickityClassNameList}
        flickityRef={c => setFlickityRef(c)}
        ref={c => setFlickityReactRef(c)}
        options={flickityOptions}
      >
        {renderChildren() /* Use 'CompareAndSelect.Item' component. */}
      </Flickity>
    </div>
  )

  function renderChildren() {
    return React.Children.map(children, (child, index) => {
      if (!child) {
        return null
      }

      var {
        inputAttributes,
        label,
        icon,
        title,
        value,
        valueSuffix,
        buttonLabel,
        infoIcon,
        descriptionTitle = "Description",
        description,
        checkbox,
        legalTitle = "Legal",
        legal,
        radioDescription,
        radioValue,
        radioInfoIcon,
        children,
        ...otherProps /* in <div> tag */
      } = child.props

      // generated
      const inputClassNameList = classNames({
        "c-compare-and-select__hidden-input ": true,
        "c-radio__input ": radioButtonSection
      }).trim()

      // generated
      function renderUnlessButtonVariant() {
        if (buttonVariant == false) {
          return (
            <input
              className={inputClassNameList}
              type="radio"
              name={inputGroup}
              id={`${inputGroup}-${index}`}
              checked={selected == index}
              readOnly
              {...inputAttributes}
            ></input>
          )
        }
        return null
      }

      // generated
      const divClassNameList = classNames({
        "c-compare-and-select ": true,
        "c-compare-and-select--selected ": (buttonVariant && (selected == index)),
        "c-radio__label-container ": radioButtonSection
      }).trim()

      // generated
      function renderIfLabel() {
        if (label) {
          return <div className="c-compare-and-select__label">{label}</div>
        }
        return null
      }

      // generated
      function renderIfIcon() {
        if (icon) {
          return <i className={iClassNameList}></i>
        }
        return null
      }

      // generated
      const iClassNameList = classNames({
        "c-compare-and-select__icon ": icon,
        "c-icon ": icon,
        [`c-icon--[${icon}] `]: icon
      }).trim()

      // generated
      function renderIfValue() {
        if (value) {
          return (
            <div className="c-compare-and-select__value">
              <p className="c-compare-and-select__value-total">{value}</p>
              <p className="c-compare-and-select__value-suffix">
                {valueSuffix}
                {renderInfoIcon()}
              </p>
            </div>
          )
        }
        return null
      }

      // generated
      function renderIfChildren() {
        if (children) {
          return (
            <ul className="c-compare-and-select__list c-icon-list c-icon-list--small">
              {
                children /* Use 'SelectAndCompare.CompareAndSelect.IconListItem' component. */
              }
            </ul>
          )
        }
        return null
      }

      // generated
      function renderIfDescription() {
        if (description) {
          return (
            <p className="c-compare-and-select__description">
              {descriptionTitle && <><strong>{descriptionTitle}</strong>: </>}{description}
            </p>
          )
        }
        return null
      }

      // generated
      function renderInfoIcon() {
        if (infoIcon) {
          return (<>{infoIcon}</>)
        }
        return null
      }

      // generated
      function renderIfButton() {
        if (buttonLabel) {
          return (
            <React.Fragment>
              {buttonVariant ? (
                <React.Fragment>
                  <button onClick={(e) => updateSelected(index)} className="c-compare-and-select__button c-btn">
                    {buttonLabel}
                  </button>
                </React.Fragment>
              ) : (
                  <React.Fragment>
                    <label
                      className="c-compare-and-select__button c-btn"
                      htmlFor={`${inputGroup}-${index}`}
                      onClick={(e) => updateSelected(index)}
                    >
                      {buttonLabel}
                    </label>
                  </React.Fragment>
                )}
            </React.Fragment>
          )
        }
        return null
      }

      // generated
      function renderIfCheckbox() {
        if (checkbox) {
          return <div className="c-compare-and-select__checkbox">{checkbox}</div>
        }
        return null
      }

      // generated
      function renderIfLegal() {
        if (legal) {
          return (
            <p className="c-compare-and-select__legal">
              {legalTitle && <><strong>{legalTitle}</strong>: </>}{legal}
            </p>
          )
        }
        return null
      }

      // generated
      function renderIfRadioButtonSection() {
        if (radioButtonSection) {
          return (
            <label
              className="c-radio c-compare-and-select__radio"
              htmlFor={`${inputGroup}-${index}`}
              onClick={(e) => (e.target.className.search("info-icon") == -1) && updateSelected(index)}
            >
              <div className="c-radio__label c-compare-and-select__radio-label" checked={buttonVariant && (selected == index)}>
                {radioValue}{radioInfoIcon}
                <div className="c-compare-and-select__radio-description">
                  {radioDescription}
                </div>
              </div>
            </label>
          )
        }
        return null
      }

      // generated main result
      return (
        <div
          {...otherProps}
          className="c-compare-and-select__item js-compare-and-select__item"
        // style={{height:'100%'}}
        >
          {renderUnlessButtonVariant()}
          <div className={divClassNameList}>
            {renderIfLabel()}
            <header className="c-compare-and-select__title">
              {renderIfIcon()}
              <h4 className="c-compare-and-select__title-text">{title}</h4>
            </header>
            {renderIfValue()}
            {renderIfChildren()}
            {renderIfDescription()}
            {renderIfButton()}
            {renderIfCheckbox()}
            {renderIfLegal()}
            {renderIfRadioButtonSection()}
          </div>
        </div>
      )
    }
    )
  }
}

CompareAndSelect.propTypes = {
  testId: PropTypes.string, // Added for data-testid attribute.
  inputGroup: PropTypes.string.isRequired, // Bronson template: 'input-group'.
  buttonVariant: PropTypes.bool, // Bronson template: 'is-button-variant'.
  radioButtonSection: PropTypes.bool, // Bronson template: 'has-radio-button-section'.
  children: PropTypes.node // Bronson template: 'compare-and-select'. Use 'CompareAndSelect.Item' component.
}

CompareAndSelect.Item = ({ children }) => {
  return children
}

CompareAndSelect.Item.displayName = "CompareAndSelect.Item"

CompareAndSelect.Item.propTypes = {
  defaultSelected: PropTypes.number,
  inputAttributes: PropTypes.object, // Bronson template: 'input-attribute'.
  label: PropTypes.string, // Bronson template: 'label'.
  icon: PropTypes.string, // Bronson template: 'icon'.
  title: PropTypes.string, // Bronson template: 'title'.
  value: PropTypes.string, // Bronson template: 'value'.
  valueSuffix: PropTypes.string,
  icon: PropTypes.string, // Bronson template: 'icon'.
  buttonLabel: PropTypes.string,
  descriptionTitle: PropTypes.string,
  description: PropTypes.node, // Bronson template: 'description'.
  checkbox: PropTypes.node, // Bronson template: 'checkbox'.
  legalTitle: PropTypes.string,
  legal: PropTypes.node,
  radioDescription: PropTypes.node, // Bronson template: 'radio-description'.
  radioValue: PropTypes.string, // Bronson template: 'radio-value'.
  radioInfoIcon: PropTypes.node, // Bronson template: 'info-icon'.
  infoIcon: PropTypes.node,
  children: PropTypes.node // Bronson template: 'icon-list'. Use 'SelectAndCompare.CompareAndSelect.IconList' component.
}

/*
 * Bronson IconListItem component (nested).
 *
 * Generated React component. Do not modify.
 */
function IconListItem({
  icon,
  iconDescription,
  children,
  ...otherProps /* in <li> tag */
}) {
  // generated
  const iClassNameList = classNames({
    "c-icon-list__icon ": true,
    "c-icon ": true,
    [`c-icon--[${icon}] `]: icon
  }).trim()

  // generated main result
  return (
    <li {...otherProps}>
      <i className={iClassNameList} aria-hidden="true"></i>
      <span className="u-visually-hidden">{`${iconDescription}: `}</span>
      {children}
    </li>
  )
}

IconListItem.propTypes = {
  icon: PropTypes.string.isRequired, // Bronson template: 'icon'.
  iconDescription: PropTypes.string.isRequired, // Bronson template: 'icon-description'.
  children: PropTypes.node // Bronson template: 'text'.
}

IconListItem.displayName = "CompareAndSelect.IconListItem"
CompareAndSelect.IconListItem = IconListItem

