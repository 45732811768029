import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

/*
 * Bronson Tag component.
 *
 * Generated React component. Do not modify.
 */
export function Tag({
  className,
  children,
  close,
  testId,
  success,
  warning,
  error,
  info,
  positive,
  negative,
  onCloseClick,
  ...otherProps /* in <span> tag */
}) {
  // generated
  const spanClassNameList = classNames(
    {
      "c-tag ": true,
      // main class modifier convenience prop extension
      "c-tag--success ": success, // Convenience prop from Bronson variants.
      "c-tag--warning ": warning, // Convenience prop from Bronson variants.
      "c-tag--error ": error, // Convenience prop from Bronson variants.
      "c-tag--info ": info, // Convenience prop from Bronson variants.
      "c-tag--positive ": positive, // Convenience prop from Bronson variants.
      "c-tag--negative ": negative // Convenience prop from Bronson variants.
    },
    className
  ).trim()

  // generated
  function renderIfClose() {
    if (close) {
      return (
        <button className="c-tag__close" title="Close" type="button" onClick={onCloseClick}></button>
      )
    }
    return null
  }

  // generated main result
  return (
    <span {...otherProps} data-testid={testId} className={spanClassNameList}>
      <span className="c-tag__label">{children}</span>
      {renderIfClose()}
    </span>
  )
}

Tag.propTypes = {
  className: PropTypes.string, // Bronson template: 'modifier'.
  children: PropTypes.node, // Bronson template: 'label'.
  close: PropTypes.bool, // Bronson template: 'close'.
  testId: PropTypes.string, // Added for data-testid attribute.
  /* Convenience props */
  success: PropTypes.bool, // Convenience prop for c-tag--success (Bronson template: 'modifier').
  warning: PropTypes.bool, // Convenience prop for c-tag--warning (Bronson template: 'modifier').
  error: PropTypes.bool, // Convenience prop for c-tag--error (Bronson template: 'modifier').
  info: PropTypes.bool, // Convenience prop for c-tag--error (Bronson template: 'modifier').
  positive: PropTypes.bool, // Convenience prop for c-tag--positive (Bronson template: 'modifier').
  negative: PropTypes.bool // Convenience prop for c-tag--negative (Bronson template: 'modifier').
}
