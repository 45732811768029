import React, { useRef, useState, useEffect } from 'react'
import PropTypes from "prop-types"
import classNames from "classnames"
import { InfoIcon } from "../InfoIcon/InfoIcon"

const AccordionContext = React.createContext({})

/*
 * Bronson Accordion component.
 */
export function Accordion({
  className,
  multipleOpen,
  // defaultOpen,
  testId,
  children,
  withBackground,
  // disableJsFx,
  ...otherProps /* in <div> tag */
}) {

  const [open, setOpen] = useState([])

  const contextValue = {
    // the value of "open" state
    "isOpen": isOpen,
    // callback function to toggle the open states
    'toggleOpen': (ref) => toggleOpen(ref),
    // callback function to update the open states
    'updateOpen': (ref, refToBeOpen) => updateOpen(ref, refToBeOpen)
  }

  function isOpen(ref) {
    return open.indexOf(ref) >= 0
  }

  /*
   * Updates the open state for a given item (ref) with a target condition (refToBeOpen bool).
   * Subsequently updates other states accordingly.
   */
  function updateOpen(ref, refToBeOpen) {

    if (multipleOpen) {
      const index = open.indexOf(ref)
      if (refToBeOpen) {
        // should be open
        if (index < 0) {
          // not yet in open list, add
          setOpen(open.concat(open, [ref]))
        }
      } else {
        // should not be open
        if (index >= 0) {
          // present in list, remove
          setOpen(open.filter(value => value !== ref))
        }
      }
    } else {
      if (refToBeOpen) {
        // set as only open
        setOpen([ref])
      } else {
        // set none to be open
        setOpen([])
      }
    }
  }

  /*
   * Change open state for the given item (ref) without the need to know the current state.
   */
  function toggleOpen(ref) {
    updateOpen(ref, open.indexOf(ref) >= 0 ? false : true)
  }

  // generated
  const divClassNameList = classNames(
    {
      "c-accordion ": true,
      "js-accordion ": true,
      "js-is-fx ": false, // && !disableJsFx, // currently generally disabled
      // main class modifier convenience prop extension
      "c-accordion--bg-panel ": withBackground // Convenience prop from Bronson variants.
    },
    className
  ).trim()

  // generated main result
  return (
    <div
      {...otherProps}
      data-testid={testId}
      className={divClassNameList}
      data-accordion-multiple={!multipleOpen && "true"}
    >
      <AccordionContext.Provider value={contextValue}>
        {children}
      </AccordionContext.Provider>
    </div>
  )
}

Accordion.propTypes = {
  className: PropTypes.string, // Bronson template: 'accordion-modifier'.
  multipleOpen: PropTypes.bool, // Bronson template: 'group'.
  defaultOpen: props => {
    if (!props.defaultOpen && props.defaultOpen !== 0) {
      return undefined
    }

    if (Array.isArray(props.defaultOpen) && !props.multipleOpen) {
      return new Error(
        'defaultOpen prop should be an integer when multipleOpen is false'
      )
    }

    if (Number.isInteger(props.defaultOpen) && props.multipleOpen) {
      return new Error(
        'defaultOpen prop should be an array of integers when multipleOpen is true'
      )
    }

    return undefined
  },
  testId: PropTypes.string, // Added for data-testid attribute.
  // disableJsFx: PropTypes.bool, // Bronson template: 'disable-js-fx'.
  /* Convenience props */
  withBackground: PropTypes.bool // Convenience prop for c-accordion--bg-panel (Bronson template: 'accordion-modifier').
}

/*
 * Bronson AccordionItem component.
 */
export function AccordionItem({
  className,
  title,
  subtitle,
  icon,
  tooltip,
  testId,
  children,
  defaultOpen,
  ...otherProps
}) {

  const itemRef = useRef(null);

  const context = React.useContext(AccordionContext)

  useEffect(() => {
    context.updateOpen(itemRef, defaultOpen)
  }, [defaultOpen])

  const childDivClassNameList = classNames(
    {
      "c-accordion__header ": true,
      "js-accordion__title ": true,
      "is-active": context.isOpen(itemRef),
    },
    className
  ).trim()

  /**
   * Needed e.g. because IconList within the panel would result in icons staying on screen even when collapsed.
   * This is handled by Bronson JS otherwise.
   */
  const panelStyle = {
    display: context.isOpen(itemRef) ? 'inherit' : 'none',
  }

  return (
    <React.Fragment>
      <div
        className={childDivClassNameList}
        onClick={() => context.toggleOpen(itemRef)}
        data-testid={testId}
        {...otherProps}
      >
        <span className="c-accordion__title-icon"></span>
        <h3 className="c-accordion__title">
          <button
            className="c-accordion__title-label js-accordion__title-label"
            aria-expanded={context.isOpen(itemRef)}
            type="button"
          >
            <span className="c-accordion__title-label-text">{title}</span>&#160;
                        {icon && <InfoIcon element="span" icon={icon}>{tooltip}</InfoIcon>}
          </button>
        </h3>
        {subtitle && <p className="c-accordion__subtitle">{subtitle}</p>}
      </div>
      <div
        className="c-accordion__panel js-accordion__panel"
        aria-hidden={!context.isOpen(itemRef)}
        style={panelStyle}
      >
        <div className="c-accordion__content">
          {children}
        </div>
      </div>
    </React.Fragment>
  )
}


Accordion.Item = AccordionItem
Accordion.Item.displayName = 'Accordion.Item'

Accordion.Item.propTypes = {
  defaultOpen: PropTypes.bool,
  title: PropTypes.node, // Bronson template: 'title'.
  subtitle: PropTypes.node, // Bronson template: 'subtitle'.
  icon: PropTypes.string, // Bronson template: 'icon'.
  tooltip: PropTypes.node, // Bronson template: 'tooltip'.
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node
}