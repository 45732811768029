import React from 'react'
import PropTypes from 'prop-types'
import { PageWrap, pageWrapSizes } from '../PageWrap/PageWrap'
import classNames from "classnames"

export function ContentSection({
  className,
  children,
  altBackground,
  pageWrap,
  pageWrapSize
}) {

  function renderContent() {
    if (pageWrap || pageWrapSize) {
      return <PageWrap size={pageWrapSize}>{children}</PageWrap>
    } else {
      return children
    }
  }

  const classNameList = classNames(
    {
      "o-content-section ": true,
    },
    className
  ).trim()

  return (
    <section
      className={classNameList}
      data-theme={altBackground ? 'alternative' : undefined} // TODO clarify 
    >
      {renderContent()}
    </section>
  )
}

ContentSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  altBackground: PropTypes.bool, // TODO clarify 
  pageWrap: PropTypes.bool,
  pageWrapSize: PropTypes.oneOf(pageWrapSizes)
}

function ComponentWrapper({
  className,
  children
}) {

  const classNameList = classNames(
    {
      "o-component-wrapper ": true,
    },
    className
  ).trim()

  return <div className={classNameList}>{children}</div>
}

ComponentWrapper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired
}

ContentSection.ComponentWrapper = ComponentWrapper