import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

/*
 * Bronson FavoriteButton component.
 *
 * Generated React component. Do not modify.
 */
export function FavoriteButton({
  className,
  labelOn,
  labelOff,
  testId,
  pressed: externalPressed = false,
  onPressedChange,
  ...otherProps /* in <button> tag */
}) {

  const [pressed, setPressed] = useState(externalPressed)

  useEffect(() => {
    setPressed(externalPressed)
  }, [externalPressed])

  function togglePressed() {
    setPressed(!pressed)
    onPressedChange(!pressed)
  }

  // generated
  const buttonClassNameList = classNames(
    {
      "c-fav-button ": true,
      "js-fav-button ": true
    },
    className
  ).trim()

  // generated main result
  return (
    <button
      {...otherProps}
      className={buttonClassNameList}
      data-testid={testId}
      aria-pressed={pressed ? 'true' : 'false'} // force attribute appearance also when 'false' 
      onClick={(event) => togglePressed()}
    >
      <span className="c-fav-button__icon">
        <svg className="c-fav-button__svg" viewBox="0 0 24 24">
          <path d="m22.5 9.6-7.2-1-3.3-6.6-3.2 6.6-7.3 1 5.3 5.2-1.3 7.2 6.5-3.4 6.5 3.4-1.2-7.2z"></path>
        </svg>
      </span>
      <span className="c-fav-button__label c-fav-button__label--on">
        {labelOn}
      </span>
      <span className="c-fav-button__label c-fav-button__label--off">
        {labelOff}
      </span>
    </button>
  )
}

FavoriteButton.propTypes = {
  className: PropTypes.string, // Bronson template: 'fav-button-modifier'.
  labelOn: PropTypes.node, // Bronson template: 'fav-button-label-on'.
  labelOff: PropTypes.node, // Bronson template: 'fav-button-label-off'.
  testId: PropTypes.string // Added for data-testid attribute.
}
