import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

/*
 * Bronson ButtonContainer object (component).
 *
 */
export function ButtonContainer({
    children,
    className,
    testId,
    center,
    left,
    right,
    nav,
    ...otherProps /* in <div> tag */
}) {

    const divClassNameList = classNames(
        {
            "o-button-container ": true,
            // main class modifier convenience prop extension
            "o-button-container--center ": center, // Convenience prop
            "o-button-container--left ": left, // Convenience prop
            "o-button-container--right ": right, // Convenience prop
            "o-button-container--nav ": nav, // Convenience prop
        },
        className
    ).trim()

    function renderChildren() {
        return React.Children.map(children, (child, index) => {
            if (!child) {
                return null
            }
            let buttonClassName = "o-button-container__button " + (child.props.className ? child.props.className : '')
            return React.cloneElement(child, { className: buttonClassName })
        })
    }

    // generated main result
    return (
        <div
            {...otherProps}
            data-testid={testId}
            className={divClassNameList}
        >
            {renderChildren()}
        </div>
    )
}

ButtonContainer.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    testId: PropTypes.string, // Added for data-testid attribute.
    /* Convenience props */
    center: PropTypes.bool,
    left: PropTypes.bool,
    right: PropTypes.bool,
    nav: PropTypes.bool,
}
