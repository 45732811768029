// IntersectionObserver polyfill
//import 'intersection-observer'

// ObjectFitImages polyfill
import objectFitImages from 'object-fit-images'

const isIE11 = (!!window.MSInputMethodContext && !!document.documentMode);

if (isIE11) {
  window.objectFitImages = objectFitImages
}

// console.log("imported polyfills")
