import React, { useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { FormFieldContext } from "../FormField/FormFieldContext"

/*
 * Bronson Textarea component.
 *
 * Generated React component. Do not modify.
 */
export function Textarea({
  modifierStates,
  id,
  error,
  testId,
  onChange,
  children,
  ...otherProps /* in <textarea> tag */
}) {

  const context = React.useContext(FormFieldContext)

  useEffect(() => {
    if (context && context.setElementActive) {
      context.setElementActive(!!children)
    }
  }, [children])

  // generated
  const textareaClassNameList = classNames({
    "c-input__input ": true,
    [`${modifierStates} `]: modifierStates
  }).trim()

  // generated main result
  return (
    <div className="c-input c-input--textarea">
      <textarea
        data-testid={testId}
        className={textareaClassNameList}
        id={id}
        cols="30"
        rows="10"
        aria-labelledby={error && `error-${id}`}
        onChange={(event) => [ 
          onChange && onChange(event), 
          context.setElementActive && context.setElementActive(!!event.target.value) 
        ]}
        {...otherProps}
        defaultValue={children}
      ></textarea>
    </div>
  )
}

Textarea.propTypes = {
  modifierStates: PropTypes.string, // Bronson template: 'modifier-states'.
  id: PropTypes.string, // Bronson template: 'id'.
  // Bronson template: 'attr'. Replaced by {...otherProps}.
  error: PropTypes.bool, // Bronson template: 'error'.
  testId: PropTypes.string, // Added for data-testid attribute.
  onChange: PropTypes.func
}
