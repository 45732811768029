import React from 'react'
import PropTypes from 'prop-types'
import Tippy from '@tippy.js/react'

export function Tooltip({ 
  content, 
  children,
  ...otherProps }) 
{
  
  function renderChildren() {
    if (typeof children === 'string') {
      return <span className="c-tooltip">{children}</span>
    }
    return children
  }

  return (
    <Tippy
      content={content}
      arrow
      hideOnClick={false}
      interactive
      placement="bottom"
      theme="default"
      animation="default"
      {...otherProps}
    >
      {renderChildren()}
    </Tippy>
  )
}

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired
}
