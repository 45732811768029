import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

/*
 * Bronson DefinitionTable component.
 *
 * Generated React component. Do not modify.
 */
export function DefinitionTable({
  className,
  definitionTableTitle,
  testId,
  children,
  noWrap,
  ...otherProps /* in <h3> tag */
}) {
  // generated
  const divClassNameList = classNames(
    {
      "c-definition-table ": true,
      // main class modifier convenience prop extension
      "c-definition-table--no-wrap ": noWrap // Convenience prop from Bronson variants.
    },
    className
  ).trim()

  // generated
  function renderIfDefinitionTableTitle() {
    if (definitionTableTitle) {
      return (
        <h3
          {...otherProps}
          data-testid={testId}
          className="c-definition-table__title"
        >
          {definitionTableTitle}
        </h3>
      )
    }
    return null
  }

  // generated main result
  return (
    <div className={divClassNameList}>
      {renderIfDefinitionTableTitle()}
      {children /* Use 'DefinitionTable.Section' component. */}
    </div>
  )
}

DefinitionTable.propTypes = {
  className: PropTypes.string, // Bronson template: 'definition-table-modifier'.
  definitionTableTitle: PropTypes.string, // Bronson template: 'definition-table-title'.
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node, // Bronson template: 'definition-table-section'. Use 'DefinitionTable.Section' component.
  /* Convenience props */
  noWrap: PropTypes.bool // Convenience prop for c-definition-table--no-wrap (Bronson template: 'definition-table-modifier').
}

/*
 * Bronson DefinitionTableItem component (nested).
 *
 * Generated React component. Do not modify.
 */
function DefinitionTableItem({
  title,
  titleComment,
  children,
  dataComment,
  ...otherProps /* in no tag */
}) {
  // generated
  function renderIfTitleComment() {
    if (titleComment) {
      return (
        <span className="c-definition-table__item-title-comment">
          {titleComment}
        </span>
      )
    }
    return null
  }

  // generated
  function renderIfDataComment() {
    if (dataComment) {
      return (
        <span className="c-definition-table__item-data-comment">
          {dataComment}
        </span>
      )
    }
    return null
  }

  // generated main result
  return (
    <React.Fragment>
      <dt className="c-definition-table__item-title">
        {renderIfTitleComment()}
        {title}
      </dt>
      <dd className="c-definition-table__item-data">
        {renderIfDataComment()}
        {children}
      </dd>
    </React.Fragment>
  )
}

DefinitionTableItem.propTypes = {
  title: PropTypes.node, // Bronson template: 'title'.
  titleComment: PropTypes.node, // Bronson template: 'title-comment'.
  children: PropTypes.node, // Bronson template: 'data'.
  dataComment: PropTypes.node // Bronson template: 'data-comment'.
}

DefinitionTableItem.displayName = "DefinitionTable.Item"
DefinitionTable.Item = DefinitionTableItem

/*
 * Bronson DefinitionTableSection component (nested).
 *
 * Generated React component. Do not modify.
 */
function DefinitionTableSection({
  innerTitle,
  summary,
  summaryTitle,
  summaryData,
  summaryInformation,
  children,
  ...otherProps /* in no tag */
}) {
  // generated
  function renderIfDefinitionTableSectionInnerTitle() {
    if (innerTitle) {
      return <h4 className="c-definition-table__inner-title">{innerTitle}</h4>
    }
    return null
  }

  // generated
  function renderIfDefinitionTableSectionSummary() {
    if (summary) {
      return (
        <React.Fragment>
          <dl className="c-definition-table__section c-definition-table__section--summary">
            <dt className="c-definition-table__item-title">{summaryTitle}</dt>
            <dd className="c-definition-table__item-data">{summaryData}</dd>
          </dl>
          <p className="c-definition-table__information">
            {summaryInformation}
          </p>
        </React.Fragment>
      )
    }
    return null
  }

  // generated main result
  return (
    <React.Fragment>
      {renderIfDefinitionTableSectionInnerTitle()}
      <dl className="c-definition-table__section">
        {children /* Use 'DefinitionTable.Item' component. */}
      </dl>
      {renderIfDefinitionTableSectionSummary()}
    </React.Fragment>
  )
}

DefinitionTableSection.propTypes = {
  innerTitle: PropTypes.node, // Bronson template: 'definition-table-section-inner-title'.
  summary: PropTypes.bool, // Bronson template: 'definition-table-section-summary'.
  summaryTitle: PropTypes.node, // Bronson template: 'definition-table-section-summary-title'.
  summaryData: PropTypes.node, // Bronson template: 'definition-table-section-summary-data'.
  summaryInformation: PropTypes.node, // Bronson template: 'definition-table-section-summary-information'.
  children: PropTypes.node // Bronson template: 'definition-table-item'. Use 'DefinitionTable.Item' component.
}

DefinitionTableSection.displayName = "DefinitionTable.Section"
DefinitionTable.Section = DefinitionTableSection
