import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import 'intersection-observer'
import { useInView } from 'react-intersection-observer'

/*
 * Bronson FloatingBar component.
 *
 * Generated React component. Do not modify.
 */
export function FloatingBar({
  target,
  className,
  children,
  testId,
  ...otherProps /* in <a> tag */
}) {

  const [ref, inView, entry] = useInView({
    /* Optional options */
    threshold: 0,
  })

  const [lastScrollTop, setLastScrollTop] = useState(false)
  const [aboveEnter, setAboveEnter] = useState(false)
  const [aboveLeave, setAboveLeave] = useState(false)
  const [belowEnter, setBelowEnter] = useState(false)
  const [belowLeave, setBelowLeave] = useState(false)

  useEffect(() => {
    if (entry) {
      const top = entry.boundingClientRect.top

      if (inView) {
        if (lastScrollTop > top) {
          //console.log("in >")
          setAboveEnter(false)
          setAboveLeave(false)
          setBelowEnter(true)
          setBelowLeave(false)
        } else {
          //console.log("in <")
          setAboveEnter(true)
          setAboveLeave(false)
          setBelowEnter(false)
          setBelowLeave(false)
        }
      }
      if (!inView) {
        if (lastScrollTop > top) {
          // console.log("out >")
          setAboveEnter(false)
          setAboveLeave(true)
          setBelowEnter(false)
          setBelowLeave(false)
        } else {
          // console.log("out <")
          setAboveEnter(false)
          setAboveLeave(false)
          setBelowEnter(false)
          setBelowLeave(true)
        }
      }
      setLastScrollTop(top)
    }

  }, [inView, entry])

  const visible = !entry || entry.boundingClientRect.top === 0 || inView

  const aClass = classNames(
    {
      'c-floating-bar  ': true,
      'js-scroll-observer  ': true,
      'has-target-inside-view  ': visible,
      'has-target-outside-view  ': !visible,
      'has-target-enter-above-view  ': aboveEnter,
      'has-target-leave-above-view  ': aboveLeave,
      'has-target-enter-below-view  ': belowEnter,
      'has-target-leave-below-view  ': belowLeave,
    },
    className
  ).trim()

  const scrollToRef = (scrollToRef) => window.scrollTo(0, scrollToRef.current.offsetTop)

  // generated main result
  return (
    <>
      <span ref={ref}></span> { /* The position tracker */ }
      <a
        {...otherProps}
        data-testid={testId}
        className={aClass}
        data-scroll
        onClick={(e) => { scrollToRef(target) }}
      >
        <div className="c-floating-bar__inner">
          {children}
        </div>
      </a>
    </>
  )
}

FloatingBar.propTypes = {
  className: PropTypes.string,
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node
}
