import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { ReactLink, linkToType } from "../Link/Link"

/*
 * Bronson BackToTop component.
 *
 * Generated React component. Do not modify.
 */
export function BackToTop({
  children,
  button,
  testId,
  onClick,
  href,
  to,
  ...otherProps /* in <div> tag */
}) {

  const heightFactor = 0.5

  const [isSticky, setSticky] = useState(false);
  const [stickyOffset, setStickyOffset] = useState(null);
  const [needUpdateState, setNeedUpdateState] = useState(true);

  const ref = useRef(null);

  // Update stickyness state
  useEffect(() => {
    if (ref.current) {

      // Calculate the sticky offset if requested (by set to null) 
      if (stickyOffset === null) {
        let offset = document.documentElement.clientHeight * heightFactor
        // onsole.log(`set stickyOffset: ${offset}`)
        setStickyOffset(offset)
      }
            
      // Determine a change in stickyness
      let shouldBeSticky = (window.pageYOffset > stickyOffset);
      if (shouldBeSticky !== isSticky) {
        setSticky(shouldBeSticky)
      }
      // If the element itself would be part of the caluclation, use it e.g. like
      //   setSticky(ref.current.getBoundingClientRect().top <= 0);

      // We just did the requested update, set need update state to false
      setNeedUpdateState(false)
    }
  }, [needUpdateState]);

  const handleResize = () => {
    // reset sticky offset, will be recalculated with updated state
    setStickyOffset(null)
    setNeedUpdateState(true)
  };

  const handleScroll = () => {
    setNeedUpdateState(true)
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // generated
  const spanClassNameList = classNames({
    "c-back-to-top__text ": true,
    "c-btn__text ": button
  }).trim()

  // generated
  function renderIfLabel() {
    if (children) {
      return <span className={spanClassNameList}>{children}</span>
    }
    return null
  }

  const onClickSpecific = (onClick || href ? onClick : (() => window.scrollTo({ top: 0, behavior: 'smooth' })))

  function renderIfButton() {
    if (button) {
      return (
        <button
          type="button"
          className="c-btn c-btn--secondary c-btn--icon c-back-to-top__trigger js-back-to-top-trigger"
          onClick={onClickSpecific}
        >
          <i className="c-back-to-top__icon"></i>
          {renderIfLabel()}
        </button>
      )
    }
  }

  function renderIfNoButton() {
    if (!button) {
      return (
        // Note: Do not use a <Link> as it contains other classNames and content structures.
        <ReactLink
          className="c-back-to-top__trigger js-back-to-top-trigger"
          to={to ? to : (href ? href : "#")}
          onClick={onClickSpecific}
        >
          <i className="c-back-to-top__icon"></i>
          {renderIfLabel()}
        </ReactLink>
      )
    }
  }

  const divClassNameList = classNames({
    "c-back-to-top ": true,
    "js-back-to-top ": true,
    "is-sticky ": isSticky
  }).trim()

  // generated main result
  return (
    <div
      {...otherProps}
      ref={ref}
      data-testid={testId}
      className={divClassNameList}
    >
      <div className="c-back-to-top__pagewrap">
        {renderIfButton()}
        {renderIfNoButton()}
      </div>
    </div >
  )
}

BackToTop.propTypes = {
  children: PropTypes.node, // Bronson template: 'label'.
  button: PropTypes.bool, // Bronson template: 'button'.
  testId: PropTypes.string, // Added for data-testid attribute.
  onClick: PropTypes.func, // Added for onClick attribute.
  to: linkToType,
  href: PropTypes.string
}
