import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

/*
 * Bronson Progress component.
 *
 * Generated React component. Do not modify.
 */
export function Progress({
  className,
  value,
  testId,
  success,
  error,
  ...otherProps /* in <progress> tag */
}) {
  // generated
  const progressClassNameList = classNames(
    {
      "c-progress ": true,
      // main class modifier convenience prop extension
      "is-success ": success, // Convenience prop from Bronson variants.
      "is-error ": error // Convenience prop from Bronson variants.
    },
    className
  ).trim()

  // generated main result
  return (
    <progress
      {...otherProps}
      data-testid={testId}
      className={progressClassNameList}
      value={value}
      max="100"
    ></progress>
  )
}

Progress.propTypes = {
  className: PropTypes.string, // Bronson template: 'progress-modifier'.
  value: PropTypes.string, // Bronson template: 'progress-value'.
  testId: PropTypes.string, // Added for data-testid attribute.
  /* Convenience props */
  success: PropTypes.bool, // Convenience prop for is-success (Bronson template: 'progress-modifier').
  error: PropTypes.bool // Convenience prop for is-error (Bronson template: 'progress-modifier').
}
