import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export function Main({children, ...otherProps }) {

  const mainClassName = classNames({
    "o-main ": true
  }).trim()

  return (
    <main
      className={mainClassName}
      {...otherProps}
    >
      {children}
    </main>)
}

Main.propTypes = {
  children: PropTypes.node,
}