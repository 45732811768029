import React from "react"
import PropTypes from "prop-types"

/*
 * Bronson Paragraph component.
 *
 * Generated React component. Do not modify.
 */
export function Paragraph({
  className,
  children,
  testId,
  ...otherProps /* in <p> tag */
}) {
  // generated main result
  return (
    <p {...otherProps} data-testid={testId} className={className}>
      {children}
    </p>
  )
}

Paragraph.propTypes = {
  className: PropTypes.string, // Bronson template: 'paragraph-modifier'.
  children: PropTypes.node, // Bronson template: 'paragraph-content'.
  testId: PropTypes.string // Added for data-testid attribute.
}
