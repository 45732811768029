import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Button } from "../Button/Button"

/**
 * Bronson DataOverview component.
 *
 * Generated React component. Do not modify.
 */
export function DataOverview({
  className,
  icon,
  title,
  children,
  testId,
  buttonLabel,
  buttonProps,
  ...otherProps /* in <article> tag */
}) {
  // generated
  const articleClassNameList = classNames(
    {
      "c-data-overview ": true
    },
    className
  ).trim()

  // generated
  const iClassNameList = classNames({
    "c-icon ": true,
    [`c-icon--[${icon}] `]: true
  }).trim()

  // generated
  function renderIfDataOverviewIcon() {
    if (icon) {
      return (
        <div className="c-data-overview__icon">
          <i className={iClassNameList} aria-hidden="true"></i>
        </div>
      )
    }
    return null
  }

  function renderIfDataOverviewButton() {
    if (buttonLabel) {
      return (
        <Button
          element="a"
          link
          small
          className="c-data-overview__button"
          icon="true" // as currently in Bronson template for activating the css icon    
          iconViaCss
          iconReversed
          {...buttonProps}
        >
          {buttonLabel}
        </Button>    
      )      
    }
    return null
  }

  // generated main result
  return (
    <article
      {...otherProps}
      data-testid={testId}
      className={articleClassNameList}
    >
      <header className="c-data-overview__header">
        {renderIfDataOverviewIcon()}
        <h4 className="c-data-overview__title">{title}</h4>
        {renderIfDataOverviewButton()}
      </header>
      <div className="c-data-overview__body">{children}</div>
    </article>
  )
}

DataOverview.propTypes = {
  /**
   * Bronson template: 'modifiers'.
   */
  className: PropTypes.string,
  /**
   * Bronson template: 'data-overview-icon'.
   */
  icon: PropTypes.string,
  /**
   * Bronson template: 'data-overview-title'.
   */
  title: PropTypes.string,
  /**
   * Bronson template: 'content'.
   */
  children: PropTypes.node, 
  buttonLabel: PropTypes.string,
  buttonProps: PropTypes.object,
  /**
   * Added for data-testid attribute.
   */
  testId: PropTypes.string 
}
