import React, { useState } from 'react'
import PropTypes from "prop-types"
import classNames from "classnames"

/*
 * Bronson Wizard component.
 *
 * Generated React component. Do not modify.
 */
export function Wizard({
  componentVariantTitle,
  className,
  collapseBreakpoint,
  collapsedTitle,
  collapsedText,
  collapsedCounter,
  children,
  noInteraction,
  ...otherProps /* in no tag */
}) {

  const [open, setOpen] = useState(false)

  // generated
  const navClassNameList = classNames(
    {
      "c-wizard ": true,
      "js-wizard ": true,
      "js-is-ready ": true, // needed because used in css
      // main class modifier convenience prop extension
      "c-wizard--no-interaction ": noInteraction // Convenience prop from Bronson variants.
    },
    className
  ).trim()

  // generated
  const olClassNameList = classNames(
    {
      "c-wizard__list  ": true,
      "is-closed  ": !open,
      "js-wizard__list  ": true,
      "js-is-ready  ": true
    },
    className
  ).trim()

  // generated main result
  return (
    <React.Fragment>
      <nav
        className={navClassNameList}
        data-wizard-collapse={collapseBreakpoint} // applies condition
      >
        <button
          className="c-wizard__trigger js-wizard__trigger"
          type="button"
          aria-expanded={open}
          title={collapsedTitle}
          onClick={() => setOpen(!open)}
        >
          <i className="c-wizard__trigger-icon" aria-hidden="true"></i>
          {collapsedText && (
            <span className="c-wizard__trigger-text">{collapsedText}</span>
          )}
          {collapsedCounter && (
            <span className="c-wizard__trigger-counter">{collapsedCounter}</span>
          )}
        </button>
        <ol className={olClassNameList}>
          {children /* Use 'Wizard.Item' component. */}
        </ol>
      </nav>
    </React.Fragment>
  )
}

Wizard.propTypes = {
  className: PropTypes.string, // Bronson template: 'wizard-modifier'.
  collapsedTitle: PropTypes.string,
  collapseBreakpoint: PropTypes.string, // Bronson template: 'collapse-breakpoint'.
  collapsedText: PropTypes.string, // Bronson template: 'wizard-trigger-text'.
  collapsedCounter: PropTypes.string, // Bronson template: 'wizard-trigger-counter'.
  children: PropTypes.node, // Bronson template: 'wizard-item'. Use 'Wizard.Item' component.
  /* Convenience props */
  noInteraction: PropTypes.bool // Convenience prop for c-wizard--no-interaction (Bronson template: 'wizard-modifier').
}

/*
 * Bronson WizardItem component (nested).
 *
 * Generated React component. Do not modify.
 */
function WizardItem({
  done,
  active,
  error,
  disabled,
  noInteraction,
  hiddenCurrentStepText,
  className,
  onClick,
  testId,
  children,
  ...otherProps /* in <a> tag */
}) {
  // generated
  const liClassNameList = classNames(
    {
      "c-wizard__item ": true,
      "is-done  ": done,
      "is-active  ": active,
      "is-error  ": error,
      "is-disabled  ": disabled,
      "has-no-interaction": noInteraction,
    },
    className
  ).trim()

  // generated
  function renderIfStateActive() {
    if (active) {
      return <span className="u-visually-hidden">{hiddenCurrentStepText || "Current Step"}:</span>
    }
    return null
  }

  // generated main result
  return (
    <li data-testid={testId} className={liClassNameList}>
      <a
        className="c-wizard__link"
        onClick={onClick}
        aria-disabled={disabled}
        {...otherProps} 
      >
        {renderIfStateActive()}
        <span className="c-wizard__link-title">{children}</span>
      </a>
    </li>
  )
}

WizardItem.propTypes = {
  children: PropTypes.node, // Bronson template: 'title'.
  testId: PropTypes.string, // Added for data-testid attribute.
  onClick: PropTypes.func, // Added for onClick attribute.
  hiddenCurrentStepText: PropTypes.string,
  done: PropTypes.bool,
  active: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  noInteraction: PropTypes.bool
}

WizardItem.displayName = "Wizard.Item"
Wizard.Item = WizardItem
