import React, { useEffect } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { FormFieldContext } from "../FormField/FormFieldContext"

/*
 * Bronson Input component.
 *
 * Generated React component. Do not modify.
 */
export function Input({
  addonType,
  type,
  modifierStates,
  id,
  error,
  success,
  stateIcon,
  addonClassName,
  className,
  addonIcon,
  addonText,
  testId,
  ghost,
  noBackground,
  reversed,
  onChange,
  defaultValue,
  ...otherProps /* in <input> tag */
}) {

  const context = React.useContext(FormFieldContext)

  useEffect(() => {
    if (context && context.setElementActive) {
      context.setElementActive(!!defaultValue)
    }
  }, [defaultValue])

  // generated
  const divClassNameList = classNames(
    {
      "c-input ": true,
      [`c-input--${type} `]: type,
      // main class modifier convenience prop extension
      "c-input--ghost ": ghost, // Convenience prop from Bronson variants.
      "c-input__addon--no-background ": noBackground, // Convenience prop from Bronson variants.
      "c-input--reversed ": reversed // Convenience prop from Bronson variants.
    },
    className
  ).trim()

  // generated
  const inputClassNameList = classNames({
    "c-input__input ": true,
    "is-success": success,
    "is-error": error,
    [`${modifierStates} `]: modifierStates
  }).trim()

  // generated
  function renderIfStateIcon() {
    if (stateIcon) {
      return <i className="c-input__state-icon"></i>
    }
    return null
  }

  // generated
  const spanClassNameList = classNames(
    {
      "c-input__addon ": true
    },
    addonClassName
  ).trim()

  // generated
  const iClassNameList = classNames({
    "c-icon ": true,
    [`c-icon--[${addonIcon}] `]: true
  }).trim()

  // generated
  function renderIfInputAddonIconOrInputAddonText() {
    if (addonIcon || addonText) {
      return (
        <span
          className={spanClassNameList}
          type={addonType} // applies condition
        >
          {addonIcon ? (
            <React.Fragment>
              <i className={iClassNameList} aria-hidden="true"></i>
            </React.Fragment>
          ) : (
            addonText
          )}
        </span>
      )
    }
    return null
  }

  // generated main result
  return (
    <div className={divClassNameList}>
      <input
        data-testid={testId}
        className={inputClassNameList}
        id={id}
        type={`${type || "text"}`}
        aria-labelledby={error && `error-${id}`}
        onChange={(event) => [ 
          onChange && onChange(event), 
          context.setElementActive && context.setElementActive(!!event.target.value) 
        ]}
        defaultValue={defaultValue}
        {...otherProps}
      ></input>
      {renderIfStateIcon()}
      {renderIfInputAddonIconOrInputAddonText()}
    </div>
  )
}

Input.propTypes = {
  addonClassName: PropTypes.string, // Bronson template: 'input-addon-modifiers'.
  type: PropTypes.string, // Bronson template: 'type'.
  modifierStates: PropTypes.string, // Bronson template: 'modifier-states'.
  id: PropTypes.string, // Bronson template: 'id'.
  // Bronson template: 'attr'. Replaced by {...otherProps}.
  error: PropTypes.bool, // Bronson template: 'error'.
  success: PropTypes.bool,
  stateIcon: PropTypes.bool, // Bronson template: 'has-state-icon'.
  className: PropTypes.string, // Bronson template: 'input-modifiers'.
  addonType: PropTypes.string, // Bronson template: 'input-addon-type'.
  addonIcon: PropTypes.string, // Bronson template: 'input-addon-icon'.
  addonText: PropTypes.node, // Bronson template: 'input-addon-text'.
  testId: PropTypes.string, // Added for data-testid attribute.
  /* Convenience props */
  ghost: PropTypes.bool, // Convenience prop for c-input--ghost (Bronson template: 'input-modifiers').
  noBackground: PropTypes.bool, // Convenience prop for c-input__addon--no-background (Bronson template: 'input-modifiers').
  reversed: PropTypes.bool, // Convenience prop for c-input--reversed (Bronson template: 'input-modifiers').
  onChange: PropTypes.func
}
