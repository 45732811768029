import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

/*
 * Bronson Card component.
 *
 * Generated React component. Do not modify.
 */
export function Card({
  element,
  className,
  icon,
  imageSrc,
  imageAlt,
  imageProps,
  title,
  subtitle,
  success, 
  warning,
  error,  
  inactive,
  scrollable,
  collapsible,
  buttons,
  testId,
  children,
  ...otherProps /* in Complex tag name tag */
}) {
  // generated
  // tag containing a variable or condition
  const CustomTagCardElement = `${element}`

  // generated
  const ClassNameList = classNames(
    {
      "c-card ": true,
      "c-card--image": imageSrc || imageProps,
      "c-card--button-group": (buttons && buttons.length > 0),
      "c-card--states": success || warning || error,
      "c-card--states-success": success,
      "c-card--states-warning": warning,
      "c-card--states-error": error,
      "c-card--inactive": inactive,
      "c-card--scrollable": scrollable,
      "c-card--collapsible": collapsible,
    },
    className
  ).trim()

  // generated
  const iClassNameList = classNames({
    "c-icon ": true,
    [`c-icon--[${icon}] `]: true
  }).trim()

  // generated
  function renderIfIcon() {
    if (icon) {
      return (
        <div className="c-card__icon">
          <i className={iClassNameList} aria-hidden="true"></i>
        </div>
      )
    }
    return null
  }

  // generated
  function renderIfImage() {
    if (imageSrc || imageProps) {
      return (
        <div className="c-card__image">
          <img src={imageSrc} alt={imageAlt} {...imageProps}></img>
        </div>
      )
    }
    return null
  }

  // generated
  function renderIfSubtitle() {
    if (subtitle) {
      return <p className="c-card__subtitle">{subtitle}</p>
    }
    return null
  }

  // generated
  function renderIfTitle() {
    if (title) {
      return (
        <header className="c-card__header">
          <h4 className="c-card__title">{title}</h4>
          {renderIfSubtitle()}
        </header>
      )
    }
    return null
  }

  // generated
  function renderIfButtons() {
    if (buttons) {
      return (
        <footer className="c-card__footer">
          <div className="o-button-container">
            {buttons}
          </div>
        </footer>
      )
    }
    return null
  }

  // generated main result
  return (
    <CustomTagCardElement
      {...otherProps}
      data-testid={testId}
      className={ClassNameList}
    >
      {renderIfIcon()}
      {renderIfImage()}
      {renderIfTitle()}
      <div className="c-card__body">{children}</div>
      {renderIfButtons()}
    </CustomTagCardElement>
  )
}

Card.propTypes = {
  element: PropTypes.string, // Bronson template: 'card-element'.
  icon: PropTypes.string, // Bronson template: 'card-icon'.
  imageSrc: PropTypes.string, // Bronson template: 'card-image'.
  imageAlt: PropTypes.string,
  imageProps: PropTypes.object,
  title: PropTypes.string, // Bronson template: 'card-title'.
  subtitle: PropTypes.string, // Bronson template: 'card-subtitle'.
  className: PropTypes.string, // Bronson template: 'card-modifier'.
  buttons: PropTypes.array, // Bronson template: 'card-buttons'.
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node,  // Bronson template: 'card-buttons'. Use 'Card.Button' component.
  success: PropTypes.bool, 
  warning: PropTypes.bool,
  error: PropTypes.bool,  
  inactive: PropTypes.bool,
  scrollable: PropTypes.bool,
  collapsible: PropTypes.bool
}

