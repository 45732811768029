import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

/*
 * Bronson TileSelectGroup component.
 *
 */
export function TileSelectGroup({
  className,
  testId,
  layoutItemClassName,
  children,
  ...otherProps /* in <div> tag */
}) {

  function renderChildren() {
    return React.Children.map(children, (child, index) => {
      if (!child) {
        return null
      }

      const layoutItemClassNameList = classNames(
        {
          "o-layout__item ": true
        },
        layoutItemClassName
      ).trim()

      return (
        <div className={layoutItemClassNameList}>
          {child}
        </div>)
    })
  }

  const divClassNameList = classNames(
    {
      "o-layout ": true,
      "o-layout--equal-height ": true
    },
    className
  ).trim()

  // generated main result
  return (
    <div
      {...otherProps}
      data-testid={testId}
      className={divClassNameList}
    >
      {renderChildren()}
    </div>
  )
}

TileSelectGroup.propTypes = {
  className: PropTypes.string,
  testId: PropTypes.string, // Added for data-testid attribute.
  layoutItemClassName: PropTypes.string, // Bronson template: 'layout-modifier'.
  children: PropTypes.node // Bronson template: 'tile-select'. Use 'TileSelectGroup.TileSelect' component.
}
