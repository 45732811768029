import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

/*
 * Bronson Header component.
 *
 * Generated React component. Do not modify.
 */
export function Header({
  className,
  logo,
  testId,
  children,
  staticHeader, // static is a reserved keyword 
  ...otherProps /* in <header> tag */
}) {
  // generated
  const divClassNameList = classNames(
    {
      "c-header ": true,
      // main class modifier convenience prop extension
      "c-header--static ": staticHeader // Convenience prop from Bronson variants.
    },
    className
  ).trim()

  // generated main result
  return (
    <header {...otherProps} data-testid={testId} className="c-header__wrapper">
      <div className={divClassNameList}>
        <div className="c-header__content-wrapper">
          <div className="c-header__logo">{logo}</div>
          <div className="c-header__nav">
            {children}
          </div>
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  className: PropTypes.string, // Bronson template: 'header-modifier'.
  logo: PropTypes.node, // Bronson template: 'bronson-logo'.
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node,
  /* Convenience props */
  staticHeader: PropTypes.bool // Convenience prop for c-header--static (Bronson template: 'header-modifier').
}
