import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

/*
 * Bronson Button component.
 *
 * Generated React component. Do not modify.
 */
export function Button({
  element = "button",
  iconViaCss,
  onClick,
  disabled,
  ariaExpanded,
  ariaLabel,
  icon,
  iconReversed,
  title,
  children,
  hiddenLabel,
  className,
  testId,
  secondary,
  full,
  link,
  small,
  round,
  simple,
  truncate,
  ...otherProps /* in <button> tag */
}) {

  // generated
  // tag containing a variable or condition
  const CustomElement = `${element}`

  // generated
  const buttonClassNameList = classNames(
    {
      "c-btn ": true,
      "c-btn--icon ": icon && !link,
      "c-btn--icon-only ": icon && (!children || hiddenLabel),
      // main class modifier convenience prop extension
      "c-btn--secondary ": secondary, // Convenience prop from Bronson variants.
      "c-btn--full ": full, // Convenience prop from Bronson variants.
      "c-btn--link ": link, // Convenience prop from Bronson variants.
      "c-btn--small ": small, // Convenience prop from Bronson variants.
      "c-btn--round ": round, // Convenience prop from Bronson variants.
      "c-btn--simple ": simple, // Convenience prop from Bronson variants.
      "c-btn--truncate ": truncate // Convenience prop from Bronson variants.
    },
    className
  ).trim()

  // generated
  const iClassNameList = classNames({
    "c-btn__icon ": true,
    "c-icon ": !iconViaCss,
    [`c-icon--[${icon}] `]: !iconViaCss
  }).trim()

  // generated
  function renderIfButtonIcon() {
    if (icon) {
      return <i className={iClassNameList}></i>
    }
    return null
  }

  const spanClassNameList = classNames({
    "c-btn__text ": true,
    "u-visually-hidden ": hiddenLabel,
  }).trim()

  // generated
  function renderIfButtonLabel() {
    if (children) {
      return <span className={spanClassNameList}>{children}</span>
    }
    return null
  }

  // generated main result
  return (
    <CustomElement
      title={title || children}
      {...otherProps}
      data-testid={testId}
      onClick={onClick} // applies condition
      className={buttonClassNameList}
      disabled={disabled}
      aria-disabled={disabled && "true"}
      aria-expanded={ariaExpanded} // applies condition
      aria-label={ariaLabel} // applies condition
    >
      {iconReversed ? (
        <React.Fragment>
          {renderIfButtonIcon()}
          {renderIfButtonLabel()}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {renderIfButtonLabel()}
          {renderIfButtonIcon()}
        </React.Fragment>
      )}
    </CustomElement>
  )
}

Button.propTypes = {
  element: PropTypes.string,
  iconViaCss: PropTypes.bool, // Bronson template: 'button-icon-via-css'.
  onClick: PropTypes.func, // Bronson template: 'button-href'.
  disabled: PropTypes.bool, // Bronson template: 'button-disabled'.
  ariaExpanded: PropTypes.string, // Bronson template: 'button-aria-expanded'.
  ariaLabel: PropTypes.string, // Bronson template: 'button-aria-label'.
  // Bronson template: 'button-attribute'. Replaced by {...otherProps}.
  icon: PropTypes.string, // Bronson template: 'button-icon'.
  iconReversed: PropTypes.bool, // Bronson template: 'button-icon-reversed'.
  title: PropTypes.string, // Bronson template: 'button-title'.
  children: PropTypes.node, // Bronson template: 'button-label'.
  className: PropTypes.string, // Bronson template: 'button-modifier'.
  hiddenLabel: PropTypes.bool, // Bronson template: 'button-hidden-label'.
  testId: PropTypes.string, // Added for data-testid attribute.
  /* Convenience props */
  secondary: PropTypes.bool, // Convenience prop for c-btn--secondary (Bronson template: 'button-modifier').
  full: PropTypes.bool, // Convenience prop for c-btn--full (Bronson template: 'button-modifier').
  link: PropTypes.bool, // Convenience prop for c-btn--link (Bronson template: 'button-modifier').
  small: PropTypes.bool, // Convenience prop for c-btn--small (Bronson template: 'button-modifier').
  round: PropTypes.bool, // Convenience prop for c-btn--round (Bronson template: 'button-modifier').
  simple: PropTypes.bool, // Convenience prop for c-btn--simple (Bronson template: 'button-modifier').
  truncate: PropTypes.bool // Convenience prop for c-btn--truncate (Bronson template: 'button-modifier').
}
