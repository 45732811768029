import React, { useRef, useEffect } from 'react'
import PropTypes from "prop-types"
import Plyr from 'plyr'

/*
 * Bronson Video component.
 *
 */
export function Video({
  testid,
  selfHosted,
  poster,
  src,
  alt,
  iconUrl,
  autoPlay,
  loop,
  service,
  id,
  ...otherProps /* in <video> or <div> tag */
}) {

  const videoRef = useRef(null)
  const plyrInstance = useRef(null)

  useEffect(() => {
    plyrInit()
  })

  function plyrInit() {
    plyrInstance.current = new Plyr(videoRef.current, {
      debug: false,
      autoPlay: autoPlay,
      invertTime: false,
      controls: [
        'play',
        'play-large',
        'progress',
        'current-time',
        'mute',
        'volume',
        'captions',
        'fullscreen',
      ],
      fullscreen: {
        fallback: true,
      },
      youtube: {
        noCookie: true,
      },
      urls: {
        youtube: {
          api: '', // Disable YouTube API (for title/aspect ratio calculation) calls through third-party noembed.com service.
        },
      },
    })
  }

  const plyrConfig = {}
  if (iconUrl) {
    plyrConfig.iconUrl = iconUrl
  }

  // generated main result
  return (
    <React.Fragment>
      {selfHosted ? (
        <React.Fragment>
          <video
            poster={poster}
            className="js-video"
            data-plyr-config={JSON.stringify(plyrConfig)}
            controls
            autoPlay={autoPlay}
            loop={loop}
            {...otherProps}
          >
            {src &&
              Object.entries(src).map(entry => (
                <source
                  key={`${entry[0]}${entry[1]}`}
                  type={entry[0]}
                  src={entry[1]}
                />
              ))}
            {alt}
          </video>
        </React.Fragment>
      ) : (
          <React.Fragment>
            <div
              data-plyr-provider={service}
              data-plyr-embed-id={id}
              data-plyr-config={JSON.stringify(plyrConfig)}
              className="js-video"
              {...otherProps}
            ></div>
          </React.Fragment>
        )}
    </React.Fragment>
  )
}

Video.propTypes = {
  testId: PropTypes.string,
  selfHosted: PropTypes.bool, // Bronson template: 'video-is-self-hosted'.
  poster: PropTypes.string,
  src: PropTypes.objectOf(PropTypes.string),
  alt: PropTypes.string,
  iconUrl: PropTypes.string, // Bronson template: 'path'.
  autoPlay: PropTypes.bool, // Bronson template: 'video-has-autoplay'.
  loop: PropTypes.bool, // Bronson template: 'video-has-loop'.
  service: PropTypes.string, // Bronson template: 'video-service'.
  id: PropTypes.string, // Bronson template: 'video-id'.
}
