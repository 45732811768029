import React from "react"
import PropTypes from "prop-types"

/*
 * Bronson TagList component.
 *
 * Generated React component. Do not modify.
 */
export function TagList({
  testId,
  children,
  ...otherProps /* in <ul>, <li> tag */
}) {
  // generated
  function renderChildren() {
    return React.Children.map(children, (child, index) => {
      if (!child) {
        return null
      }
      return <li {...otherProps}>{child}</li>
    })
  }

  // generated main result
  return (
    <ul {...otherProps} data-testid={testId} className="c-tag-list">
      {renderChildren()}
    </ul>
  )
}

TagList.propTypes = {
  include: PropTypes.string, // Bronson template: 'include'.
  testId: PropTypes.string // Added for data-testid attribute.
}
