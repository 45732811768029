import React from "react"
import PropTypes from "prop-types"

/*
 * Bronson Hr component.
 *
 * Generated React component. Do not modify.
 */
export function Hr({ testId, ...otherProps /* in <hr> tag */ }) {
  // generated main result
  return <hr {...otherProps} data-testid={testId}></hr>
}

Hr.propTypes = {
  testId: PropTypes.string // Added for data-testid attribute.
}
