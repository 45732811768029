import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

/*
 * Bronson Badge component.
 *
 * Generated React component. Do not modify.
 */
export function Badge({
  modifier,
  children,
  label,
  testId,
  ...otherProps /* in <span> tag */
}) {
  // generated
  const spanClassNameList = classNames({
    "c-badge ": true,
    [`c-badge--${modifier} `]: modifier
  }).trim()

  // generated main result
  return (
    <React.Fragment>
      <span {...otherProps} data-testid={testId} className={spanClassNameList}>
        {children}
      </span>
      {label}
    </React.Fragment>
  )
}

Badge.propTypes = {
  modifier: PropTypes.string, // Bronson template: 'badge-modifier'.
  children: PropTypes.node, // Bronson template: 'badge-content'.
  label: PropTypes.string, // Bronson template: 'badge-label'.
  testId: PropTypes.string // Added for data-testid attribute.
}
