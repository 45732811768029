import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Spinner } from "../Spinner/Spinner"

/*
 * Bronson Upload component.
 *
 * Generated React component. Do not modify.
 */
export function Upload({
  className,
  descriptionText,
  descriptionSupplementaryText,
  buttonText,
  mouseoverText,
  id,
  onChange,
  disabledText,
  disabledSupplementaryText,
  processingText,
  testId,
  mouseover,
  loading,
  disabled,
  accept,
  multiple,
  ...otherProps /* in <label> tag */
}) {
  // generated
  const labelClassNameList = classNames(
    {
      "c-upload ": true,
      // main class modifier convenience prop extension
      "has-mouseover ": mouseover, // Convenience prop from Bronson variants.
      "is-loading ": loading, // Convenience prop from Bronson variants.
      "is-disabled ": disabled // Convenience prop from Bronson variants.
    },
    className
  ).trim()

  // generated
  function renderIfUploadButtonText() {
    if (buttonText) {
      return (
        <span className="c-upload__actions">
          <span className="c-upload__button c-btn">
            <span>{buttonText}</span>
          </span>
        </span>
      )
    }
    return null
  }

  // generated
  function renderIfUploadDisabledSupplementaryText() {
    if (disabledSupplementaryText) {
      return (
        <span className="c-upload__disabled-supplementary-text">
          {disabledSupplementaryText}
        </span>
      )
    }
    return null
  }

  // generated main result
  return (
    <label
      {...otherProps}
      data-testid={testId}
      className={labelClassNameList}
      htmlFor={id}
    >
      <input className="c-upload__input" type="file" id={id} onChange={onChange} accept={accept} multiple={multiple}></input>
      <span className="c-upload__description">
        <i className="c-upload__icon" aria-hidden="true"></i>
        <span className="c-upload__description-text">{descriptionText}</span>
        <span className="c-upload__description-supplementary-text">
          {descriptionSupplementaryText}
        </span>
      </span>
      {renderIfUploadButtonText()}
      <span className="c-upload__mouseover">
        <i className="c-upload__mouseover-icon" aria-hidden="true"></i>
        <span className="c-upload__mouseover-text">{mouseoverText}</span>
      </span>
      <span className="c-upload__loader">
        <span className="c-upload__loader-text">{processingText ? processingText : "Processing..."}</span>
        <Spinner center></Spinner>
      </span>
      <span className="c-upload__disabled">
        <i className="c-upload__disabled-icon" aria-hidden="true"></i>
        <span className="c-upload__disabled-text">{disabledText}</span>
        {renderIfUploadDisabledSupplementaryText()}
      </span>
    </label>
  )
}

Upload.propTypes = {
  className: PropTypes.string, // Bronson template: 'upload-modifier'.
  descriptionText: PropTypes.node, // Bronson template: 'upload-description-text'.
  descriptionSupplementaryText: PropTypes.node, // Bronson template: 'upload-description-supplementary-text'.
  buttonText: PropTypes.string, // Bronson template: 'upload-button-text'.
  mouseoverText: PropTypes.node, // Bronson template: 'upload-mouseover-text'.
  onChange: PropTypes.func,
  id: PropTypes.string, // Bronson template: 'id'.
  disabledText: PropTypes.node, // Bronson template: 'upload-disabled-text'.
  disabledSupplementaryText: PropTypes.node, // Bronson template: 'upload-disabled-supplementary-text'.
  processingText: PropTypes.node,
  testId: PropTypes.string, // Added for data-testid attribute.
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  /* Convenience props */
  mouseover: PropTypes.bool, // Convenience prop for has-mouseover (Bronson template: 'upload-modifier').
  loading: PropTypes.bool, // Convenience prop for is-loading (Bronson template: 'upload-modifier').
  disabled: PropTypes.bool // Convenience prop for is-disabled (Bronson template: 'upload-modifier').
}
