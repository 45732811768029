import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

/*
 * Bronson IconList component.
 *
 * Generated React component. Do not modify.
 */
export function IconList({
  className,
  testId,
  children,
  ...otherProps /* in <ul> tag */
}) {
  // generated
  const ulClassNameList = classNames(
    {
      "c-icon-list ": true
    },
    className
  ).trim()

  // generated main result
  return (
    <ul {...otherProps} data-testid={testId} className={ulClassNameList}>
      {children /* Use 'IconList.Item' component. */}
    </ul>
  )
}

IconList.propTypes = {
  className: PropTypes.string, // Bronson template: 'icon-list-modifier'.
  testId: PropTypes.string, // Added for data-testid attribute.
  children: PropTypes.node // Bronson template: 'item'. Use 'IconList.Item' component.
}

/*
 * Bronson Item component (nested).
 *
 * Generated React component. Do not modify.
 */
function Item({
  icon,
  success,
  iconClassName,
  iconMeaning,
  children,
  ...otherProps /* in <li> tag */
}) {
  // generated
  const iClassNameList = classNames(
    {
      "c-icon-list__icon ": true,
      "c-icon ": true,
      [`c-icon--[semantic-${icon}] `]: true,
      "u-text-success ": success
    },
    iconClassName
  ).trim()

  // generated
  function renderIfItemIconMeaning() {
    if (iconMeaning) {
      return <span className="u-visually-hidden">{iconMeaning}</span>
    }
    return null
  }

  // generated main result
  return (
    <li {...otherProps}>
      <i className={iClassNameList} aria-hidden="true"></i>
      <span>&#160;</span>
      {renderIfItemIconMeaning()}
      {children}
    </li>
  )
}

Item.propTypes = {
  icon: PropTypes.string, // Bronson template: 'item-icon'.
  success: PropTypes.bool, // Bronson template: 'item-success'.
  iconClassName: PropTypes.string, // Bronson template: 'item-icon-modifier'.
  iconMeaning: PropTypes.string, // Bronson template: 'item-icon-meaning'.
  children: PropTypes.node // Bronson template: 'item-content'.
}

Item.displayName = "IconList.Item"
IconList.Item = Item
