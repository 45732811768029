import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Sticky from 'react-sticky-el'

/*
 * Bronson StickyContainer component.
 *
 * Generated React component. Do not modify.
 */
export function StickyContainer({
  children,
  testId,
  ...otherProps /* in <div> tag */
}) {
  // generated main result
  return (
    <Sticky>
      <div
        {...otherProps}
        data-testid={testId}
        className="c-sticky-container js-sticky-container"
      >
        <div className="c-sticky-container__inner">{children}</div>
      </div>
    </Sticky>
  )
}

StickyContainer.propTypes = {
  children: PropTypes.node,
  testId: PropTypes.string // Added for data-testid attribute.
}
