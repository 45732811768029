import React from "react"
import PropTypes from "prop-types"

/*
 * Bronson Heading component.
 *
 * Generated React component. Do not modify.
 */
export function Heading({
  level,
  children,
  testId,
  ...otherProps /* in Complex tag name tag */
}) {
  // generated
  // tag containing a variable or condition
  const CustomTagHeadingsLevel = `h${level}`

  // generated main result
  return (
    <CustomTagHeadingsLevel {...otherProps} data-testid={testId}>
      {children}
    </CustomTagHeadingsLevel>
  )
}

Heading.propTypes = {
  level: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]), // Bronson template: 'headings-level'.
  children: PropTypes.node, // Bronson template: 'text'.
  testId: PropTypes.string // Added for data-testid attribute.
}
