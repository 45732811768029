import React from "react"
import PropTypes from "prop-types"

/*
 * Bronson RadioButtonGroup component.
 *
 * Generated React component. Do not modify.
 */
export function RadioButtonGroup({
  children,
  testId,
  ...otherProps /* in <fieldset>, <span> tag */
}) {
  // generated
  function renderChildren() {
    return React.Children.map(children, (child, index) => {
      if (!child) {
        return null
      }
      return (
        <span {...otherProps} className="o-inline-group__item">
          {child}
        </span>
      )
    })
  }

  // generated main result
  return (
    <fieldset {...otherProps} data-testid={testId}>
      <div className="o-inline-group">{renderChildren()}</div>
    </fieldset>
  )
}

RadioButtonGroup.propTypes = {
  children: PropTypes.node, // Bronson template: 'content'.
  testId: PropTypes.string // Added for data-testid attribute.
}
